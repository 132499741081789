import React from "react";
import { useQuery } from '@tanstack/react-query';

// MATERIAL
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";

// APIS
import { fetchUserLogs } from "../../../apis/users";

// FUNCTIONS
import { formatDate } from "../../../functions/formatDate";


const LogDialog = ({ userName, userId, entityId, handleClose }) => {
  const { data: userLogs, status } = useQuery({
    queryKey: [ 'userLogs', entityId, userId ],
    queryFn: () => fetchUserLogs(entityId, userId),
  });

  console.log("Logs:", userLogs);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      scroll={"paper"}
    >
      <DialogTitle
        id="dialog-title"
      >
        {userName} Logs
      </DialogTitle>
      <DialogContent>
      {status === 'loading' && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
        {userLogs && userLogs.logs.length > 0 ? (
          userLogs.logs.map((log) => (
            <React.Fragment key={log.time}>
              <Typography variant="caption">
                {formatDate(log.time)}
              </Typography>
              <Typography>
                {log.method} - {log.keyName && `${log.keyName} - `}
                {log.deviceName ? log.deviceName : "Unknown Device"}
              </Typography>
            </React.Fragment>
          ))
        ) : (
          <Typography>No user logs</Typography>
        )}
        <Divider/>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            backgroundColor: "#FF9900",
            color: "#FFFFFF",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogDialog;
