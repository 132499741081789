import React, { useState } from 'react';

// LIBRARIES
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';



// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import Chip from "@mui/material/Chip";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
// import CircularProgress from "@mui/material/CircularProgress";

// ICONS
import DeleteIcon from '@mui/icons-material/Delete';
// import InfoIcon from '@mui/icons-material/Info';
// import FileCopyIcon from "@mui/icons-material/FileCopy";

// APIS
import {
  fetchDeviceAccessKeys,
  createDeviceAccessKey,
  deleteDeviceAccessKey,
} from '../../../apis/devices';

// FUNCTIONS
import { textfieldDateTime, addToDate, isoDate, diffDate, formatDate } from '../../../functions/formatDate';

const ShareDialog = ({ deviceName, deviceID, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    name: '',
    startTime: textfieldDateTime(), 
    endTime: textfieldDateTime(addToDate(new Date(),2, 'hours')),//dayjs().add(2, 'hour').format("YYYY-MM-DDTHH:mm"),
    activationTimeout: 5,
  });

  const [unlimitedTimeout, setUnlimitedTimeout] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Gets all the pending OTA keys for this device
  const { data: deviceKeys } = useQuery({ // status
    queryKey: ['deviceKeys', deviceID],
    queryFn: () => fetchDeviceAccessKeys(deviceID),
  });

  // console.log('Keys:', deviceKeys?.keys);

  const queryClient = useQueryClient();

  const createAccessKeyMutation = useMutation(createDeviceAccessKey, {
    onSuccess: () => {
      // Invalidate and refetch
      setState((prevState) => ({ ...prevState, name: '' }));
      queryClient.invalidateQueries(['deviceKeys', deviceID]);
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('Failed to create access key', { variant: 'error' });
    },
  });

  const createAccessKey = async () => {
    if (
      // dayjs(state.endTime).diff(dayjs(state.startTime), 'minute') < 60
      diffDate(state.endTime, state.startTime, 'minute') < 60
    ) {
      enqueueSnackbar('Keys must be longer then 1hr.', { variant: 'info' });
      return;
    }
    const postBody = { ...state,
        startTime: isoDate(state.startTime),//dayjs(state.startTime).toISOString(),
        endTime: isoDate(state.endTime), //dayjs(state.endTime).toISOString(),
        activationTimeout: unlimitedTimeout ? null : state.activationTimeout, 
        deviceId: deviceID, 
        pinCode: '1234' 
      };
    // console.log(postBody);
    /*

        ${data.pinCode},
        ${data.keyCount},
        */
    enqueueSnackbar('Creating access key.', { variant: 'info' });
    createAccessKeyMutation.mutate(postBody);
  };

  const deleteAccessKeyMutation = useMutation(deleteDeviceAccessKey, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['deviceKeys', deviceID]);
    },
  });

  const deleteAccessKey = async (keyData) => {
    deleteAccessKeyMutation.mutate(keyData);
  };

  const copyPW = (pw) => {
    navigator.clipboard.writeText(pw);
    enqueueSnackbar('Copied URL Key', { variant: 'success' });
  };

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle id='dialog-title'>{deviceName} Access Keys</DialogTitle>
      <DialogContent>
        <Typography>
          Generates a url that allows access to this device, without logging in.
        </Typography>
        <Typography>Only allows access between the dates specified.</Typography>
        <TextField
          label='Description'
          value={state.name || ''}
          type='text'
          onChange={handleChange}
          name='name'
          fullWidth
          sx={{ mt: 2, mb: 1 }}
        />
        <Box display='flex' alignItems='center'>
          <Typography sx={{ flexGrow: 1 }}>From: </Typography>
          <TextField
            defaultValue={state.startTime}
            type='datetime-local'
            onChange={handleChange}
            name='startTime'
          />
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography sx={{ flexGrow: 1 }}>To:</Typography>
          <TextField
            defaultValue={state.endTime}
            type='datetime-local'
            onChange={handleChange}
            name='endTime'
          />
        </Box>
        <Box display='flex' alignItems='center'>
            <Typography>Timeout:</Typography>
          <Tooltip title={unlimitedTimeout ? 'Allows the user unlimited access within the time period.' : `Gives the user ${state.activationTimeout} mins of access after first use, within the time period.`}>
          <Switch
            checked={!unlimitedTimeout}
            value={!unlimitedTimeout}
            onChange={(event) => setUnlimitedTimeout(!event.target.checked)}
            color='primary'
          />
          </Tooltip>
          {!unlimitedTimeout && (
            <TextField
              defaultValue={state.activationTimeout}
              type='number'
              onChange={handleChange}
              name='activationTimeout'
            />
          )}
          <Typography sx={{ flexGrow: 1 }}>
            {unlimitedTimeout ? 'Unlimited' : 'mins'}
          </Typography>
        </Box>
        <Button
          onClick={() => createAccessKey()}
          disabled={state.name.length < 2}
          style={{
            backgroundColor: state.name.length < 2 ? '#AAA' : '#FF9900',
            color: '#FFFFFF',
            margin: '10px',
          }}
          autoFocus
        >
          Generate
        </Button>

        {deviceKeys && deviceKeys.length > 0 && (
          <>
            <Typography variant='h6'>Pending Keys</Typography>
            <Typography variant='caption'>Click name to copy url</Typography>
          </>
        )}
        {deviceKeys &&
          deviceKeys.length > 0 &&
          deviceKeys.map((deviceKey) => (
            <React.Fragment key={deviceKey.id}>
              <Divider />
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                flexWrap='wrap'
                sx={{ m: 1 }}
                onClick={() =>
                  navigator.clipboard.writeText(`${import.meta.env.VITE_APP_URL}key/${deviceKey.url}`)
                }
              >
                <Stack>
                  <Typography variant='caption'>{formatDate(deviceKey.startTime)}</Typography>
                  <Typography variant='caption'>{formatDate(deviceKey.endTime)}</Typography>
                </Stack>

                {/* <Typography>{deviceKey.tokenCount - deviceKey.accessCount} Left</Typography> */}
                <Typography onClick={() => copyPW(deviceKey.pin)}>{deviceKey.name}</Typography>
                <Button
                  // disabled={deviceKey.startTime !== null}
                  // style={{
                  //   backgroundColor:
                  //   deviceKey.startTime !== null ? "#FFFFFF" : "#FF9900",
                  //   color: "#FFFFFF",
                  // }}
                  onClick={() => deleteAccessKey({ keyId: deviceKey.id })}
                >
                  <DeleteIcon />
                </Button>

                {/* <Tooltip
                  title={
                    deviceKey.activatedTime === null
                      ? link.SK.split("_")[1]
                      : deviceKey.activatedTime
                  }
                >
                  <Chip
                    className={
                      deviceKey.activatedTime === null
                        ? classes.open
                        : classes.closed
                    }
                    label={link.activatedTime === null ? "Active" : "Used"}
                  />
                </Tooltip> */}
              </Box>
            </React.Fragment>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            backgroundColor: '#FF9900',
            color: '#FFFFFF',
          }}
          autoFocus
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
