import * as Yup from "yup"

const validator = {
    name: /^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/,
    phone: /^[0-9]{5,15}$/,
    password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,<>])[A-Za-z\d@$!%*#?&.,<>]{8,}$/,
}

export const ResetPasswordFormSchema = (open) => Yup.object().shape({
    currentPassword:  Yup.string().required('Please enter your current password.'),
    password: Yup.string().matches(validator.password, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character.").required(),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.').required('Please confirm your new password.')
})

export const UserDetailsFormSchema = (open) => Yup.object().shape({
    email: Yup.string().email().required(),
    phone: Yup.string().matches(validator.phone).required(),
})