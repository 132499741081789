import { apiGet, apiPost } from "../utils/api";


// OLD
const inviteUser = async (postBody) => {
  console.log(postBody);
  const res = await apiPost('authentication/create-invite', postBody);
  console.log(res);
  return res;
};



// const deleteInvite = async (postBody) => {
//   // console.log(postBody);
//   const res = await apiPost('authentication/delete-invite', postBody);
//   // console.log(res);
//   return res;
// };

// export const applyInviteApi = async (postBody) => {
//   const result = await apiPost('authentication/action-invite', postBody);
//   return result;
// };

// export const checkInviteApi = async (encodedData) => {
//   const result = await apiPost('authentication/check-invite', {
//     body: {
//       invite: encodedData,
//     },
//   });
//   return result;
// };

export const ApplyInvite = async (invite) => {
  await apiPost('authentication/action-invite', {
      invite: invite,
    })
    .then(async () => {
      // Fetch a new token from cognito
      // await Auth.currentAuthenticatedUser({ bypassCache: true });
      console.log("Invite NOT Applied: FIXME: Fetch a new token from cognito")
    })
    .catch((err) => {
      throw err;
    });
};

const redeemInvite = async (postBody) => {
  const res = await apiPost('authentication/redeem-invite', postBody);
  return res;
};

const checkInvite = async (postBody) => {
  const res = await apiPost('authentication/check-invite', postBody);
  return res;
};

// export const getUsersInvitesApi = async () => {
//   const result = await apiGet('users/get-user-invites');
//   // console.log(result.invites);
//   if (result.invites.length > 0) {
//     await Auth.currentAuthenticatedUser({ bypassCache: true });
//   }
//   return result.invites;
// };

export { inviteUser, redeemInvite, checkInvite };
