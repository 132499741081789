import { apiPost } from "../../../utils/api";
import { useQueryClient, useMutation } from '@tanstack/react-query';

// export const createKeysboardQuery = async (postBody) => {
//   if(!postBody.entityId || !postBody.lat || !postBody.lng || postBody.name === '') return;
//   const res = apiPost('keysboard/create-keysboard', postBody);
//   return res;
// };

// CREATE KEYSBOARD MUTATION
const createKeysboardQuery = async (postBody) => {
  const res = apiPost('keysboard/create-keysboard', postBody);
  return res;
};

export const createKeysboardMutation = (entityId) => {
  const queryClient = useQueryClient();
  return useMutation(createKeysboardQuery, {
    onSuccess: () => {
      queryClient.invalidateQueries(['keysboards', entityId]);
    },
  });
};
