import React from 'react';
import QRCode from 'react-qr-code';
import { Buffer } from 'buffer';
import { useSnackbar } from 'notistack';

// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const QRDialog = (props) => {
  const { qrData, itemName, handleClose, display = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  // const deviceUrl = `${import.meta.env.VITE_APP_URL}${device.url}/${device.uuid.substring(0,8)}`;

  const copyDeviceURL = () => {
    console.log(qrData);
    navigator.clipboard.writeText(qrData);
    enqueueSnackbar('Copied device url.', { variant: 'success' });
  };

  //*******************************************QR CODE *************************************************** */
  function onImageDownload() {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${itemName}_QRCode`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${Buffer.from(svgData).toString('base64')}`;
  }

  async function downloadAndClose(closeWithoutDownload) {
    if (!closeWithoutDownload) {
      onImageDownload();
    }
    handleClose(false);
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='qr-dialog-title'>{itemName} QR</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <QRCode
            id='QRCode'
            // value={`https://scanseqr.com/t/azxsdc22t`} // THIS IS MAX LENGTH BEFORE SIZE INCREASE
            value={qrData}
            level='Q' // L,M,Q,H
          />
        </Box>

        {display && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant='h4'>{display}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => copyDeviceURL()}
          style={{
            backgroundColor: '#FF9900',
            color: '#FFFFFF',
          }}
        >
          Copy Url
        </Button>
        {!display && (
          <Button
            onClick={() => downloadAndClose(false)}
            style={{
              backgroundColor: '#FF9900',
              color: '#FFFFFF',
            }}
          >
            Download
          </Button>
        )}
        <Button
          onClick={() => downloadAndClose(true)}
          style={{
            backgroundColor: '#FF9900',
            color: '#FFFFFF',
          }}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRDialog;
