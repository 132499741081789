import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormikContext } from 'formik'

function FormButton(props) {
    const { label } = props
    const formik = useFormikContext()

    return (
            <Button
                variant="contained"
                type='submit'
                sx={{ backgroundImage: `linear-gradient(to right, #293443 0%, #1f2833  51%, #293443  100%)`, marginTop: 2 }}
                color='secondary'
                fullWidth
                disabled={formik.isSubmitting}
            >
                {
                    formik.isSubmitting ?
    					<CircularProgress size={25}/>
    				:
                       label
                }
            </Button>
    );
}

export default FormButton;