//@format
import Paper from '@mui/material/Paper';

export default function ProvisionDownload() {
    const downloads = [
        {name: 'Linux (amd64)', link: 'provision/scanseqr-provisionerv1-amd64-linux'},
        {name: 'Linux (386)', link: 'provision/scanseqr-provisionerv1-386-linux'},
        {name: 'Linux (arm)', link: 'provision/scanseqr-provisionerv1-arm-linux'},
        {name: 'MacOS', link: 'provision/scanseqr-provisionerv1-amd64-darwin'},
        {
            name: 'Windows (amd64)',
            link: 'provision/scanseqr-provisionerv1-amd64-windows.exe',
        },
        {name: 'Windows (386)', link: 'provision/scanseqr-provisionerv1-386-windows.exe'},
        {name: 'Windows (arm)', link: 'provision/scanseqr-provisionerv1-arm-windows.exe'},
    ];

    const instructions = [
        'Ensure your device firmware on the device is up to date. For Tasmota based devices, you need to use the custom firmware provided by Refactor to ensure correct usage. Visit https://refactorau.github.io/Tasmota-firmware/ to flash your hardware with the appropriate firmware.',
        'Download the provisioner executable for your environment. If necessary you may need to mark the download as executable. For Linux and MacOS you can use the terminal with the command "chmod +x <downloaded file>" to set the file as executable.',
        'Create a wifi.json file with the format... {"wifi_ssid":"<YOUR WIFI SSID>","wifi_pass":"<YOUR WIFI PASS>"}',
        'Create the device in ScanseQR and download the provision file.',
        'Run the provioner within 1 hour of downloading the file. You need to run the provisioner on the command line, in the same folder as you saved the wifi.json file. Provide the downloaded provision file as the first parameter to the provisioner. For example if you were on MacOS, provisioning a device called "Test", you could run the command "scanseqr-provisionerv1-amd64-darwin ./Test-provision.json".',
        'After an initial setup, the provisioner will stop until it has a connection to the shelly. At this stage turn on your device, and connect to its wifi connection to continue.',
        'If the firmware on your Shelly needs updating, the provisioner will stop again until the firmware has been upgraded. Navigate to http://192.168.33.1/ and complete the firmware upgrade. After the upgrade is complete and the device has rebooted, ensure you are still connected to the Shelly wifi and the provisioner will continue.',
    ];

    return (
        <div style={{padding: 7}}>
            <Paper
                variant="outlined"
                sx={{
                    padding: '5px',
                    marginLeft: '5px',
                    marginBottom: '5px',
                }}
            >
                <h2>Download Links</h2>
                <ul>
                    {downloads.map((v, i) => (
                        <li key={`download-${i}`}>
                            <a href={v.link}>{v.name}</a>
                        </li>
                    ))}
                </ul>
            </Paper>
            <Paper
                variant="outlined"
                sx={{
                    padding: '5px',
                    marginLeft: '5px',
                    marginBottom: '5px',
                }}
            >
                <h2>Instructions</h2>
                <ol>
                    {instructions.map((v, i) => (
                        <li key={`instructions-${i}`}>{v}</li>
                    ))}
                </ol>
            </Paper>
        </div>
    );
}
