import { apiGet, apiPost } from "../utils/api";

const fetchUsers = async (entityId) => {
  if (isNaN(parseInt(entityId))) throw new Error('Invalid parameter type');

  const postBody = { entityId: entityId };
  const result = await apiGet('users/get-entity-users', postBody);
  return result;
};

const editUser = async (postBody) => {
  if (isNaN(parseInt(postBody.id))) return;
  const res = await apiPost('users/edit-user', postBody);
  return res;
};

const deleteUser = async (postBody) => {
  if (isNaN(parseInt(postBody.userEntityId)) || isNaN(parseInt(postBody.entityId))) throw new Error('Invalid parameter type');

  const res = await apiPost('users/delete-user', postBody);
  return res;
};

const fetchUserDevicePermissions = async (entityId, userEntityId) => {
  if (isNaN(parseInt(entityId)) || isNaN(parseInt(userEntityId)))
    throw new Error('Invalid parameter type');

  const postBody = { entityId: entityId, userEntityId: userEntityId };
  const result = await apiGet('users/get-users-permissions', postBody);

  return result.permissions;
};

const modifyUsersGroups = async (postBody) => {
  if (isNaN(parseInt(postBody.entityId)) || isNaN(parseInt(postBody.userEntityId)))
  throw new Error('Invalid parameter type');

  const res = await apiPost('users/modify-users-groups', postBody);
  return res;
};

const fetchUserLogs = async (entityId, userId) => {
  if(isNaN(parseInt(userId)) || isNaN(parseInt(entityId))) {
    console.log('Invalid parameter type');
    return [];
  }
  const postBody = { userId: userId, entityId: entityId };
  const result = await apiGet('users/get-user-logs', postBody);
  return result;
};

export { modifyUsersGroups, fetchUsers, editUser, deleteUser, fetchUserDevicePermissions, fetchUserLogs };
