import { useState, useCallback, useEffect } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack';
import { useTourContext } from '../TourContext';

// MATERIAL
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';


// ICONS
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { fetchAuthSession } from 'aws-amplify/auth';

import { useUserContext } from '../contexts/UserContext.js';

const commonMenuItems = [
  'Account',
  'Invite',
  'Refresh Location',
  'Logout',
  'Help',
];

const partnerMenuItems = [
  'Account',
  'Invite',
  'Refresh Location',
  'Download Provision Application',
  'Logout',
  'Help',
];





function AppToolbar() {
  const { signOut } = useAuthenticator();
  const { user, updateUser } = useUserContext();
  const { start } = useTourContext();

  useEffect(() => {
    async function currentSession() {
      try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        // console.log('accessToken', accessToken);
        // console.log('idToken', idToken);

        if (!idToken || !idToken.payload || !idToken.payload.entities) return;

        const entities = JSON.parse(idToken.payload.entities);

        let lowestUserType = 5;
        if (entities && entities.length > 0) {
          lowestUserType =
            entities.reduce((prev, curr) => (prev.userTypeId < curr.userTypeId ? prev : curr))
              ?.userTypeId || 5;
        }
        updateUser({ ...idToken.payload, entities: entities, lowestUserType: lowestUserType });
        return;
      } catch (err) {
        console.log(err);
      }
    }
    currentSession();
  }, []);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const menuItems = user?.lowestUserType < 4 ? partnerMenuItems : commonMenuItems;

  const handleOpenUserMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };


  const handleCloseUserMenu = async (menuItem) => {
    setAnchorElMenu(null);
    switch (menuItem) {
      case 'Logout':
        signOut();
        break;
      case 'Account':
        navigate('/account');
        break;
      case 'Invite':
        navigate('/invite');
        break;
      case 'Refresh Location':
        requestGeo();
        break;
      case 'Download Provision Application':
        navigate('/provision-download');
        break;
      case 'Help':
        start();
        break;
      default:
        break;
    }
  };

  const geo = navigator.geolocation;

  const onError = useCallback((error) => {
    console.log('LOCATION ERROR', error)
    enqueueSnackbar('GeoLocation not available.', { variant: 'error' });
  }, [enqueueSnackbar]);

  const onSuccess = useCallback((data) => {
    console.log('LOCATION', data.coords)
    enqueueSnackbar(`GeoLocation available.(${data.coords.latitude.toFixed(5)},${data.coords.longitude.toFixed(5)})`, { variant: 'success' });
  }, [enqueueSnackbar]);

  const requestGeo = () => {
    geo.getCurrentPosition(onSuccess, onError)
    console.log('REQUESTING NAV SERVICES')
  }

  const goHome = () => {
    navigate('/');
  }

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar
            variant="square"
            sx={{ display: 'flex', mr: 1 }}
            alt="logo"
            onClick={goHome}
            src='/logo192.png' // TODO Get a smaller icon
          />
          <Typography
            data-tg-tour={"Click here to navigate back to the main page from anywhere within the app!"}
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: 'flex',
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ScanseQR - Manager app
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <MoreVertIcon color='primary' />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElMenu)}
              onClose={handleCloseUserMenu}
            >
              {menuItems.map((menuItem) => (
                <MenuItem key={menuItem} onClick={() => handleCloseUserMenu(menuItem)}>
                  <Typography textAlign="center">{menuItem}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppToolbar;
