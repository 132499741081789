import { apiGet, apiPost } from '../../../utils/api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

// DELETE DEVICE MUTATION
const deleteDevice = async (postBody) => {
  const res = await apiPost('devices/delete-device', postBody);
  // await Auth.currentAuthenticatedUser({ bypassCache: true });
  return res;
};

export const deleteDeviceMutation = (entityId) => {
  const queryClient = useQueryClient();
  return useMutation(deleteDevice, {
    onSuccess: () => {
      queryClient.invalidateQueries(['devices', entityId]);
    },
  });
};

// CREATE DEVICE MUTATION
const createDevice = async (postBody) => {
  const res =  await apiPost('devices/create-device', postBody);
  return res;
};

export const createDeviceMutation = (entityId) => {
  const queryClient = useQueryClient();
  return useMutation(createDevice, {
    onSuccess: () => {
      queryClient.invalidateQueries(['devices', entityId]);
    },
  });
};

// FETCH DEVICE QUERY
const fetchDeviceWithId = async (deviceId) => {
  if(isNaN(parseInt(deviceId))) return;
  const postBody = { id: deviceId };
  const result = await apiGet('devices/get-device-data', postBody);
  return result.devices;
};

export const fetchDeviceWithIdQuery = (entityId, deviceId) =>
  useQuery({
    queryKey: ['devices', entityId, deviceId],
    queryFn: () => fetchDeviceWithId(deviceId),
    // The query will not execute until follow condition is met
    enabled: !!deviceId,
  });



  

const openDevice = async (postBody) => {
  // const res =  await client.post('Scanseqr', 'devices/delete-device-group', postBody);
  const res = await apiPost(`devices/open`, postBody);
  return res;
};

const provisionDevice = async (postBody) => {
  const result = await apiGet('devices/provision', postBody);
  // const result = await client.get('Scanseqr', 'devices/get-devices-meta');
  // const res = await API.post('Scanseqr', `devices/open`, postBody);
  // console.log(result);
  return result;
};

export { openDevice, provisionDevice };
