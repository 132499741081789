import { Button, Text, View } from "@aws-amplify/ui-react";
// import { Auth } from "aws-amplify"
import { signInWithRedirect } from "aws-amplify/auth";
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
export function SocialSignUpButton({inviteData, buttonText, provider}) {

  const signIn = () =>{
    // Auth.federatedSignIn({
    //     provider: provider,
    //     customState: inviteData
    // })
    signInWithRedirect({
      provider: provider,
      customState: inviteData
  })
  }

// TODO : POSSIBLY REMOVE
  return (
    <View style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Button isFullWidth={true} style={{marginBottom: 20}} onClick={signIn}>
          {
              provider === 'google' ?
                <FcGoogle /> :
              provider === 'facebook' ?
                <FaFacebook color={'#3b5998'} /> :
              <DeviceUnknownIcon />
          }
          <Text fontWeight={300} style={{marginLeft: 20}} fontSize="1em">
            { buttonText }
          </Text>
      </Button>
    </View>
  );
}