import { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';

function TextInput(props) {
    const { label, name, type = 'text', helpText } = props
    const [showPassword, setShowPassword] = useState(false);
    const [field, meta] = useField(props);
    const hasError = Boolean(meta.touched && meta.error)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <FormControl sx={{width: '100%', marginTop: 2}} variant="outlined">
            <InputLabel htmlFor={`${name}-input`}>{label}</InputLabel>
            <OutlinedInput
                id={`${name}-input`}
                type={!showPassword ? type : 'text'}
                autoComplete={name}
                startAdornment={type === 'tel' && <InputAdornment position="start">+61</InputAdornment>}
                {...field}
                endAdornment={
                    type === 'password' && <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
        </FormControl>
    );
}

export default TextInput;