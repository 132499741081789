import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const formatDate = (date) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm");
}

const textfieldDate = (date) => {
  if (date) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  return dayjs().format("YYYY-MM-DD");
}

const textfieldDateTime = (date) => {
  if (date) {
    return dayjs(date).format("YYYY-MM-DDTHH:mm");
  }
  return dayjs().format("YYYY-MM-DDTHH:mm");
}

const isoDate = (date) => {
  if (date) {
    return dayjs(date).toISOString();
  }
  return dayjs().toISOString();
}

const addToDate = (date, amount, unit) => {
  return dayjs(date).add(amount, unit);
}

const diffDate = (date1, date2, unit) => {
  return dayjs(date1).diff(date2, unit);
}


  
  // const formatDate = (date) => {
  
  //   // TODO: maybe in a context with language etc
  //   const timeOptions = {
  //     year: "numeric",
  //     month: "numeric",
  //     day: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     //  second: "numeric",
  //     hour12: false,
  //     timeZone: "Australia/Sydney",
  //   };

  //   return new Intl.DateTimeFormat("en-AU", timeOptions).format(
  //     new Date(date)
  //   )
  // }

  const formatDateForDatetimeLocal = (date) => {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 16)
  }
export { textfieldDateTime, textfieldDate, isoDate, addToDate, diffDate, formatDate, formatDateForDatetimeLocal };
