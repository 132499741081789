
const processStatusMqttMessage = (msg, setDeviceStatus) => {
  // console.log('[' + new Date().toISOString().substring(11, 23) + '] - MQTT received', msg);
  // console.log(msg, msg[Object.getOwnPropertySymbols(msg)[0]]);
  let deviceUuid;
  let outputState = null;
  let inputState = null;

  // We subscribe to the topic <siteUuid>/# and the status
  // messages are recieved on the topic <siteUuid>/<deviceUuid>/status
//   {
//     "src": "shellypro4pm-f008d1d8b8b8",
//     "dst": "user_1",
//     "method": "NotifyStatus",
//     "params": {
//        "ts": 1631186545.04,
//        "switch:0": {
//           "id": 0,
//           "output": true,
//           "source": "button"
//        }
//     }
//  }
  if(!msg || msg === null || typeof(msg) !== 'object') return;
  // test if the value is an object
  // if (!isObject(msg)) return;

  deviceUuid = msg[Object.getOwnPropertySymbols(msg)[0]].split('/')[1];
  if (msg.params) {
    // Shelly devices have the params key/pair
    outputState = msg.params['switch:0'] ? msg.params['switch:0'].output : null;
    inputState = msg.params['input:0'] ? msg.params['input:0'].state : null;
  } else {
    // Tasmota devices have value key/pair. (NOT the dst key/pair)
    // const key = Object.keys(msg)[0];
    // const keyId = key.charAt(key.length - 1);
    // outputState = msg
  }

  // console.log('DeviceId: ', deviceUuid, outputState, inputState);
  setDeviceStatus((prevState) => {
    if (prevState[deviceUuid]) {
      if (outputState === null) outputState = prevState[deviceUuid].outputState;
      if (inputState === null) inputState = prevState[deviceUuid].inputState;
    }
    return { ...prevState, [deviceUuid]: { outputState, inputState } };
  });
};

export { processStatusMqttMessage };
