import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// import { useLocationContext } from '../components/LocationContext';



// CSS
import '../components/check.css';
import { Typography, Box, Paper } from '@mui/material';
// import React, { useState, useRef } from 'react';
// import { Box, Paper } from '@mui/material';

// UJOO15

import InviteProcess from '../components/InviteProcess';




const Invite = (props) => {
  const loc = useLocation().pathname.split('/'); // This is the url path!
  let inviteId = loc[2];
  // let staffInvite = loc[1] === 'is' ? true : false;




  const [inviteCode, setInviteCode] = useState(
    inviteId ? inviteId.split('') : ['', '', '', '', '', '']
  );

  const inputRefs = useRef([]);


  const [code, setCode] = useState('');

  const handleCharChange = (index, value) => {
    if (value.length > 1) {
      value = value.charAt(0);
    }
    if (value.match(/[0-9a-zA-Z]/)) {
      const updatedInviteCode = [
        ...inviteCode.slice(0, index),
        value.toUpperCase(),
        ...inviteCode.slice(index + 1),
      ];
      setInviteCode(updatedInviteCode);
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      } else {
          inputRefs.current[index].blur();
          setCode(updatedInviteCode.join(''));
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      if (inviteCode[index] === '' && index > 0) {
        setInviteCode((prev) => [...prev.slice(0, index - 1), '', ...prev.slice(index)]);
      } else {
        setInviteCode((prev) => [...prev.slice(0, index), '', ...prev.slice(index + 1)]);
      }
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    if (!inviteId) {
      inputRefs.current[0].focus();
    }
    if (inviteId?.length === 6) {
      setCode(inviteId);
    }
  }, [inviteId]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <Paper sx={{ padding: '20px' }}>
          <Typography
            style={{
              paddingBottom: '2px',
              textAlign: 'center',
            }}
            variant='h6'
          >
            {`Please enter the invite code`}
          </Typography>
        </Paper>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <Paper
          sx={{
            padding: '20px',
            backgroundImage: `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`,
            // If browser does not support linear-gradient
            backgroundColor: '#ff9900',
          }}
        >
          {inviteCode.map((char, index) => (
            <input
              key={index}
              type='text'
              value={char}
              onChange={(e) => handleCharChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(ref) => (inputRefs.current[index] = ref)}
              style={{
                backgroundColor: '#fff',
                border: 'none',
                textAlign: 'center',
                width: '30px',
                padding: '5px',
                margin: '5px',
              }}
            />
          ))}
        </Paper>
      </Box>
      {code && <InviteProcess code={code} />}
    </>
  );
};

export default Invite;
