import React from 'react';

// MATERIAL
import theme from '../styles/materialTheme';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';

// Staff ICONS
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // Admin
// import EngineeringIcon from "@mui/icons-material/Engineering"; // Contractor, Installer
// import HandshakeIcon from '@mui/icons-material/Handshake'; // Partner
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person'; // Staff
import Email from '@mui/icons-material/Email';
// Device ICONS
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'; // Door Open  1
import DoorFrontIcon from '@mui/icons-material/DoorFront'; // Door Closed
import LightbulbIcon from '@mui/icons-material/Lightbulb'; // 2
import TungstenIcon from '@mui/icons-material/Tungsten'; // Light On
import FlashOffIcon from '@mui/icons-material/FlashOff'; // Power off  3
import FlashOnIcon from '@mui/icons-material/FlashOn'; // Power On
import KeyIcon from '@mui/icons-material/Key';   // Key
// Entity ICONS
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'; // Company
import HomeWorkIcon from '@mui/icons-material/HomeWork'; // Partner
import MuseumIcon from '@mui/icons-material/Museum'; // MultiSite
import SvgIcon from '@mui/material/SvgIcon';
// import AccountTreeIcon from '@mui/icons-material/AccountTree'; // Site
import ExtensionIcon from '@mui/icons-material/Extension';

const iconStyle = { width: 25, height: 25, color: '#FFFFFF', margin: 1 };

const entityIcon = (entityType) => {
  switch (entityType) {
    case 'ScanseQR':
      return (
        <Avatar
          variant='square'
          sx={{ display: 'flex', mr: 1 }}
          alt='logo'
          src='./logo192.png' // TODO Get a smaller icon
        />
      );
    case 'Partner':
      return <HomeWorkIcon sx={iconStyle} />;
    case 'MultiSite':
      return <MuseumIcon sx={iconStyle} />;
    default:
      return <AccountBalanceIcon sx={iconStyle} />;
  }
};

const staffIcon = (userType) => {
  switch (userType) {
    case 'superAdmin':
      return <SupervisorAccountIcon sx={iconStyle} />;
    case 'partner':
      return <SupervisorAccountIcon sx={iconStyle} />;
    case 'multiSiteAdmin':
      return <SupervisorAccountIcon sx={iconStyle} />;
    case 'siteAdmin':
      return <SupervisorAccountIcon sx={iconStyle} />;
    case 'user':
      return <PersonIcon sx={iconStyle} />;
    case 'contractor':
      return <PersonIcon sx={iconStyle} />;
    default:
      return <Email sx={iconStyle} />;
  }
};

const integrationIcon = () => {
      return <ExtensionIcon sx={iconStyle} />;
};

const deviceIcon = (deviceType, inputState, outputState, active=true) => {
  const deviceStyle = { ml: '5px', color: outputState ? '#0F0' : active ? '#FFF' : theme.palette.secondary.main };
  switch (deviceType) {
    case 'Door': // DOOR
      if (inputState) {
        return <MeetingRoomIcon sx={deviceStyle} />;
      }
      return <DoorFrontIcon sx={deviceStyle} />;
    case 'Light': // LIGHT
      if (inputState) {
        return <TungstenIcon sx={deviceStyle} />;
      }
      return <LightbulbIcon sx={deviceStyle} />;
    case 'Power': // POWER
      if (inputState) {
        return <FlashOnIcon sx={deviceStyle} />;
      }
      return <FlashOffIcon sx={deviceStyle} />;
    case 'Key Store': // Key Store
      if (inputState) {
        return (
          <Icon sx={{ transform: 'rotate(90deg)', mt: '-10px'}}>
            <KeyIcon sx={deviceStyle} />
          </Icon>
        );
      }
      return (
        <Icon sx={{ transform: 'rotate(90deg)', mt: '-10px'}}>
          <KeyIcon sx={deviceStyle} />
        </Icon>
      );
    case 'Key Store Key': // Key
      if (inputState) {
        return <KeyIcon sx={deviceStyle} />;
      }
      return <KeyIcon sx={deviceStyle} />;
    case 'Fan': // Fan
      if (inputState) {
        return <SvgIcon sx={deviceStyle} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 11a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m.5-9c4.5 0 4.61 3.57 2.25 4.75c-.99.49-1.43 1.54-1.62 2.47c.48.2.9.51 1.22.91c3.7-2 7.68-1.21 7.68 2.37c0 4.5-3.57 4.6-4.75 2.23c-.5-.99-1.56-1.43-2.49-1.62c-.2.48-.51.89-.91 1.23c1.99 3.69 1.2 7.66-2.38 7.66c-4.5 0-4.59-3.58-2.23-4.76c.98-.49 1.42-1.53 1.62-2.45c-.49-.2-.92-.52-1.24-.92C5.96 15.85 2 15.07 2 11.5C2 7 5.56 6.89 6.74 9.26c.5.99 1.55 1.42 2.48 1.61c.19-.48.51-.9.92-1.22C8.15 5.96 8.94 2 12.5 2Z"/></svg></SvgIcon>;

      }
      return <SvgIcon sx={deviceStyle} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12.5 2C9.64 2 8.57 4.55 9.29 7.47L15 13.16c.87.21 1.81.65 2.28 1.57c1.18 2.37 4.75 2.27 4.75-2.23c0-3.58-3.98-4.37-7.68-2.37c-.32-.4-.74-.71-1.22-.91c.19-.93.63-1.98 1.62-2.47C17.11 5.57 17 2 12.5 2M3.28 4L2 5.27l2.47 2.46C3.22 7.74 2 8.87 2 11.5c0 3.57 3.96 4.35 7.65 2.37c.32.4.75.72 1.24.92c-.2.92-.64 1.96-1.62 2.45C6.91 18.42 7 22 11.5 22c2.3 0 3.44-1.64 3.44-3.79L18.73 22L20 20.72L3.28 4Z"/></svg></SvgIcon>;
    default:
      if (inputState) {
        return <MeetingRoomIcon sx={deviceStyle} />;
      }
      return <DoorFrontIcon sx={deviceStyle} />;
  }
};

export { entityIcon, staffIcon, deviceIcon, integrationIcon };
