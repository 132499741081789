import { apiPost } from '../../../utils/api';
import { useQueryClient, useMutation } from '@tanstack/react-query';


const groupQuery = async (endpoint, postBody) => {
  const res =  await apiPost(`devices/${endpoint}-device-group`, postBody);
  return res;
};

export const groupQueryMutation = (endpoint, entityId) => {
  const queryClient = useQueryClient();
  return useMutation((postBody) => groupQuery(endpoint, postBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['devices', entityId]);
    },
  });
}