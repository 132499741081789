import { apiPost } from '../../../utils/api';
import { useQueryClient, useMutation } from '@tanstack/react-query';


const inviteQuery = async (endpoint, postBody) => {
  const res =  await apiPost(`authentication/${endpoint}-invite`, postBody);
  return res;
};

export const inviteQueryMutation = (endpoint, entityId) => {
  const queryClient = useQueryClient();
  return useMutation((postBody) => inviteQuery(endpoint, postBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['users', entityId]);
    },
  });
}

// The new QR code Invite
// const generateInvite = async (postBody) => {
//   const res = await client.post('Scanseqr', 'authentication/generate-invite', postBody);
//   return res;
// };


// export { generateInvite };