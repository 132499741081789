const awsExports = {
  Auth: {

    Cognito: {
      region: import.meta.env.VITE_APP_REGION,
      userPoolEndpoint: import.meta.env.VITE_APP_REGION === "local" ? `http://${import.meta.env.VITE_APP_HOST_IP !== undefined ? import.meta.env.VITE_APP_HOST_IP : 'localhost'}:9229` : null,
      //  Amazon Cognito User Pool ID
      userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: import.meta.env.VITE_APP_IDENTITY_POOL_ID,
      identityPoolRegion: import.meta.env.VITE_APP_REGION,
      //authenticationFlowType: import.meta.env.VITE_APP_REGION === "local" ? "USER_PASSWORD_AUTH" : null,
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      // allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      aws_cognito_social_providers: import.meta.env.VITE_APP_SOCIAL_PROVIDERS !== undefined
      ? import.meta.env.VITE_APP_SOCIAL_PROVIDERS.split(',').filter((v) => v !== '')
      : ['google', 'facebook'],
      // aws_cognito_social_providers: ['google'],
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: import.meta.env.VITE_APP_COGNITO_DOMAIN,
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            // 'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: [import.meta.env.VITE_APP_REDIRECT_URL],
          redirectSignOut: [import.meta.env.VITE_APP_REDIRECT_URL],
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
        // aws_cognito_social_providers: ['google'],

      },
    },
  },
  // Auth: {
  //   identityPoolId: import.meta.env.VITE_APP_IDENTITY_POOL_ID,
  //   region: import.meta.env.VITE_APP_REGION,
  //   identityPoolRegion: import.meta.env.VITE_APP_REGION,
  //   userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
  //   userPoolWebClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
  //   aws_cognito_social_providers: import.meta.env.VITE_APP_SOCIAL_PROVIDERS !== undefined
  //     ? import.meta.env.VITE_APP_SOCIAL_PROVIDERS.split(",").filter(v => v !== "")
  //     : [
  //       "GOOGLE",
  //       "FACEBOOK"
  //     ],
  //   oauth: {
  //     domain: import.meta.env.VITE_APP_COGNITO_DOMAIN,
  //     cope: [
  //       "phone",
  //       "email",
  //       "openid",
  //       "profile",
  //     ],
  //     redirectSignIn: import.meta.env.VITE_APP_REDIRECT_URL,
  //     redirectSignOut: import.meta.env.VITE_APP_REDIRECT_URL,
  //     responseType: "code"
  //    },
  //    federationTarget: "COGNITO_USER_POOLS"
  // },
  API: {
    REST: {
      Scanseqr: {
        endpoint: import.meta.env.VITE_APP_ENDPOINT,
        region: import.meta.env.VITE_APP_REGION,
          
      },
    },
  },
};

export default awsExports;
