import React from "react";
import { useQuery } from '@tanstack/react-query';

// MATERIAL
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";

// APIS
import { fetchDeviceLogs } from "../../../apis/devices";

// FUNCTIONS
import { formatDate } from "../../../functions/formatDate";


const LogDialog = ({ deviceName, deviceID, handleClose }) => {

  // const d = new Date();

  const { data: deviceLogs, status } = useQuery({
    queryKey: [ 'deviceLogs', deviceID ],
    queryFn: () => fetchDeviceLogs(deviceID),
  });

  console.log("Logs:", deviceLogs);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      scroll={"paper"}
    >
      <DialogTitle
        id="dialog-title"
      >
        {deviceName} Logs
      </DialogTitle>
      <DialogContent>
      {status === 'loading' && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
        {deviceLogs && deviceLogs.logs.length > 0 ? (
          deviceLogs.logs.map((log) => (
            <React.Fragment key={log.time}>
              <Typography variant="caption">
                {formatDate(log.time)}
              </Typography>
              <Typography>
                {log.method} - {log.keyName && `${log.keyName} - `}
                {log.userName ? log.userName : "Unknown User"}
              </Typography>
            </React.Fragment>
          ))
        ) : (
          <Typography>No activation logs</Typography>
        )}
        <Divider/>
        {deviceLogs && deviceLogs.status.length > 0 ? (
          deviceLogs.status.map((log) => (
            <React.Fragment key={log.timeStamp}>
              <Typography variant="caption">
                {formatDate(log.timeStamp)}
              </Typography>
              <Typography>
                {log.status ? 'Online' : 'Offline'}
              </Typography>
            </React.Fragment>
          ))
        ) : (
          <Typography>Device has never connected</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            backgroundColor: "#FF9900",
            color: "#FFFFFF",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogDialog;
