import { Text, Button } from '@aws-amplify/ui-react';
import { useAuthenticator } from "@aws-amplify/ui-react";

function UserInfo(props) {
    const { signOut } = useAuthenticator();

    return (
        <div>
            <Text>Rabbit Season</Text>
            <Text>Duck Season</Text>
            <Text>Rabbit Season</Text>
            <Text>Duck Season</Text>
            <Text>Rabbit Season</Text>
            <Text>Duck Season</Text>
            <Text>Rabbit Season</Text>
            <Text>Rabbit Season</Text>
            <Text>Duck Season</Text>
            <Text>Rabbit Season</Text>
            <Text>Duck Season FIRE!</Text>
            <Button onClick={() => signOut()}>Sign Out</Button>
        </div>
    );
}

export default UserInfo;
