import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import reportWebVitals from './reportWebVitals';

import './styles/style.css'

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import p from '../package.json';

Sentry.init({
    dsn: 'https://5f2e981ce3c84085954bbb1242e0185e@sentry.refactor.com.au/19',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `${p.name}@${p.version}`,
    enabled: process.env.NODE_ENV !== 'development',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <App/>
);

  // <React.StrictMode> {/* </React.StrictMode> */}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
