import { Amplify } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import MyRoutes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';

import awsExports from './aws-exports';
import { Hub } from 'aws-amplify/utils';
import { listener } from './functions/listner';

import { SnackbarProvider } from 'notistack';

import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/materialTheme';

import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// import { UserContextProvier } from './contexts/UserContext.js';
import {TourContextProvider} from './TourContext';

//window.LOG_LEVEL = 'DEBUG';

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to Infinity, you have to invalidate the query to refetch
      staleTime: process.env.NODE_ENV === 'development' ? Infinity : 0,
      refetchOnWindowFocus: process.env.NODE_ENV === 'development' ? false : true,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.state.data !== undefined) console.log('QUERY ERROR:', error);
    },
  }),
});

// 🚀 everything entities-related will have a 1 minute staleTime
// queryClient.setQueryDefaults(entities, { staleTime: 1000 * 60 })

// Listen for AWS Amplify Auth Events
Hub.listen('auth', listener);

Amplify.configure(awsExports, {
  API: {
    REST: {
      headers: async () => {
        return { IdToken: (await fetchAuthSession()).tokens?.idToken?.toString() };
      },
    },
  },
});

// async function currentSession() {
//   try {
//     const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
//     // console.log('accessToken', accessToken);
//     // console.log('idToken', idToken);
//     return idToken;
//   } catch (err) {
//     console.log(err);
//   }
// }

function App() {
  // console.log("AUTH:", user);
  // const [user, setUser] = useState(null);
  // useEffect(() => {
  //   currentSession().then((session) => {
  //     if (!session || !session.payload || !session.payload.entities) return;

  //     const entities = JSON.parse(session.payload.entities);

  //     let lowestUserType = 5;
  //     if (entities && entities.length > 0) {
  //       lowestUserType =
  //         entities.reduce((prev, curr) => (prev.userTypeId < curr.userTypeId ? prev : curr))
  //           ?.userTypeId || 5;
  //     }
  //     setUser({ ...session.payload, entities: entities, lowestUserType: lowestUserType });
  //     // console.log('SESSION:', session);
  //   });
  // }, []);

  // currentSession();
  // console.log(awsExports)
  return (
    <div>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <TourContextProvider>
            <Router>
              <SnackbarProvider maxSnack={3}>
                <MyRoutes />
              </SnackbarProvider>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </TourContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
// export default withAuthenticator(App, {
//   socialProviders:
//     import.meta.env.VITE_APP_SOCIAL_PROVIDERS !== undefined
//       ? import.meta.env.VITE_APP_SOCIAL_PROVIDERS.split(',').filter((v) => v !== '')
//       : [],
// });

{
  /* <div>
<ThemeProvider theme={theme}>
  <QueryClientProvider client={queryClient}>
    <Router>
      <SnackbarProvider maxSnack={3}>
        {user && (
          <UserContextProvier user={user}>
            <MyRoutes />
          </UserContextProvier>
        )}
      </SnackbarProvider>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
</ThemeProvider>
</div> */
}
