/* eslint-disable react/prop-types */
import React from 'react';

// MATERIAL
import LinearProgress from '@mui/material/LinearProgress';

// COMPONENTS
import KeysBoardView from './KeysBoardView';

// HOOKS
import { useKeysboardsQuery } from './apis/useKeysboardsQuery';

// ICONS
// FUNCTIONS

function KeysboardTab({ entity }) {
  console.log('entity', entity.id);
  const { data: keysboards, status: keysboardsStatus } = useKeysboardsQuery(entity.id);
  console.log('keysboards', keysboards);

  return (
    <>
      {keysboardsStatus === 'loading' && (
        <LinearProgress color='primary' sx={{ marginBottom: 2 }} />
      )}

      {keysboards?.length > 0 && <KeysBoardView entity={entity} keysboards={keysboards} />}
    </>
  );
}

export default KeysboardTab;
