import { apiGet } from "../../../utils/api";

export const showKeylocation = async (keyId) => {
  if (isNaN(parseInt(keyId))) return [];
  const postBody = { keyId: keyId };
  // console.log('postBody', postBody);
  // const result = await API.get('Scanseqr', 'keysboard/locate-key', postBody);
  const result = await apiGet('keysboard/locate-key', postBody);
  // console.log(result);
  return result;
};
