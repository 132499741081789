import { useState, useEffect } from 'react';
import { Flex, Image, Text, useTheme, Divider } from '@aws-amplify/ui-react';
import { Heading } from '@aws-amplify/ui-react';
import { SocialSignUpButton } from './SocialSignUpButton';
import logo192 from '../assets/logo192.png';

export const LoginFormFields = {
  signIn: {
    username: {
      labelHidden: true,
    },
    password: {
      labelHidden: true,
    },
  },
  signUp: {
    email: {
      labelHidden: true,
    },
    password: {
      labelHidden: true,
    },
    confirm_password: {
      labelHidden: true,
    },
    phone_number: {
      dialCode: '+61',
      labelHidden: true,
    },
  },
};

function Header() {
  const headerText = 'Welcome to ScanseQR';
  const [charIndex, setCharIndex] = useState(1);

  useEffect(() => {
    if (charIndex < headerText.length) {
      setTimeout(() => setCharIndex(charIndex + 1), 100);
    }
  }, [charIndex]);

  return (
    <Flex justifyContent='center' alignItems={'center'} direction={'column'} style={{paddingTop: 30}} >
      <Image alt='logo' src={logo192}/>
      <Heading
        level={3}
        style={{
          marginBottom: 15,
          fontFamily: 'Courier New',
          color: '#ff9903',
        }}
      >
        {headerText.slice(0, charIndex)}
      </Heading>
    </Flex>
  );
}

function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent='center' padding={tokens.space.small}>
      <Text>&copy; ScanseQR All Rights Reserved</Text>
    </Flex>
  );
}

export const AuthComponents = (providers) => ({
  Header,
  Footer,
  SignUp: {
    Header() {
      return (
        <div>
          {providers.map((provider) => (
            <SocialSignUpButton
              key={provider}
              buttonText={`Sign Up With ${provider}`}
              provider={provider}
            />
          ))}
          {providers.length > 0 && <Divider label='OR' />}
        </div>
      );
    },
  },
  SignIn: {
    Header() {
      return (
        <div>
          {providers.map((provider) => (
            <SocialSignUpButton
              key={provider}
              buttonText={`Sign In With ${provider}`}
              provider={provider}
            />
          ))}
          {providers.length > 0 && <Divider label='OR' />}
        </div>
      );
    },
  },
});
