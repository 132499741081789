import { apiGet, apiPost } from "../utils/api";

const fetchEntityModules = async (entityId) => {
  if (isNaN(parseInt(entityId)))
    throw new Error('Invalid parameter type');

  const postBody = { entityId: entityId };
  const result = await apiGet('modules/get-entity-modules', postBody);
  // console.log(result)

  return result.modules || [];
};

const addModuleToEntity = async (postBody) => {
    if (isNaN(parseInt(postBody.entityId)) || isNaN(parseInt(postBody.moduleId)))
    throw new Error('Invalid parameter type');
  
    const res = await apiPost('modules/add-entity-module', postBody);
    // console.log("Add:", res)
    return res;
  };

  const removeModuleFromEntity = async (postBody) => {
    if (isNaN(parseInt(postBody.id)))
    throw new Error('Invalid parameter type');
  
    const res = await apiPost('modules/delete-entity-module', postBody);
    // console.log("Remove:", res)
    return res;
  };

// REMOVING DEVICES MODULE AS CURRENTLY EVERY SITE HAS THIS BUILT IN
const fetchModules = async () => {
  const result = await apiGet('modules/get-modules');
  // console.log("Modules:", result.modules.filter((el) =>el.name !== "devices"));
  return result.modules.filter((el) =>el.name !== "devices") || [];
};

export { fetchEntityModules, addModuleToEntity, removeModuleFromEntity, fetchModules };
