import React, { useState } from 'react';

// MATERIAL
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// APIS
import {
  createKeysboardMutation,
} from '../apis/createKeysboardQuery';

// CONTEXT
import { useUserContext } from '../../../contexts/UserContext.js';
import DialogLayout from '../../../components/DialogLayout';


const KeysboardDialog = (props) => {
  const { title, entity, handleClose } = props; //keysboardId

  const { user } = useUserContext();
  const userTypeId = user.entities.find((el) => el.id === entity.id)?.userTypeId || 3;



  const [keysboardData, setKeysboardData] = useState({
    // id: null,
    // uuid: '',
    // shortId: '',
    name: '',
    lat: entity.lat,
    lng: entity.lng,
    // deleted: false,
    // deletedTme: null,
    // enabled: true,
  });

  

  // Queries
  // const {
  //   data: rdeviceData,
  //   status,
  //   fetchStatus,
  // } = fetchDeviceWithIdQuery(entity.id, deviceId);

  // useEffect(() => {
  //   if (rdeviceData) {
  //     setDeviceData({ ...rdeviceData });
  //   }
  // }, [rdeviceData]);

  

  // Mutations
  const createKeysboardMutationRequest = createKeysboardMutation(entity.id);
  const handleCreate = () => {
    const postBody = { ...keysboardData, entityId: entity.id };
    console.log(postBody)
    if(!postBody.entityId || !postBody.lat || !postBody.lng || postBody.name === '') return;
    createKeysboardMutationRequest.mutate(postBody, { onSuccess: () => handleClose() });
  };

  // const deleteDeviceMutationRequest = deleteDeviceMutation(entity.id);
  // const handleRemove = () => {
  //   const postBody = {
  //     deviceId: deviceId,
  //     entityId: entity.id,
  //   };
  //   deleteDeviceMutationRequest.mutate(postBody, { onSuccess: () => handleClose() });
  // };

  const handleDelete = () => { console.log("TODO")}



  const handleChange = (e) => {
    setKeysboardData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // Custom onChange handler for parsing lat/lng string
  const handleLatLngInput = (event) => {
    const { name, value } = event.target;
    const latLngPattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
    const match = value.match(latLngPattern);

    if (match) {
      // Extract and format latitude and longitude
      let [_, lat, , lng] = match;
      lat = Number(lat).toFixed(5); // Adjust precision as needed
      lng = Number(lng).toFixed(5); // Adjust precision as needed

      // Update both fields
      handleChange({target: {name: 'lat', value: lat}})
      handleChange({target: {name: 'lng', value: lng}})
    } else {
      // Default behavior for non-matching input
      handleChange({target: {name: name, value: value}})
    }
  };



  const dialogContent = (
    <>
      {/* {status === 'loading' && fetchStatus !== 'idle' && (
          <LinearProgress color='primary' sx={{ marginBottom: 2 }} />
        )} */}
      <form noValidate>
        <TextField
          label='Keysboard Name'
          value={keysboardData.name}
          onChange={handleChange}
          variant='standard'
          name='name'
          fullWidth
          style={{ paddingBottom: '10px' }}
        />
      </form>

      <Box display="flex" data-tg-tour={"Allows users to see when they are close"}>
        <Box p={1} >
        <TextField
            name="lat"
            label="Latitude"
            value={keysboardData.lat}
            variant="standard"
            onChange={handleLatLngInput}
          />
        </Box>
        <Box p={1}>
        <TextField
            
            name="lng"
            value={keysboardData.lng}
            variant="standard"
            label="Longitude"
            onChange={handleLatLngInput}
          />
        </Box>
      </Box>
    </>
  );

  return (
    <DialogLayout
      dialogTitle={`${title} Keysboard`}
      dialogContent={dialogContent}
      handleDelete={title === 'Edit' && userTypeId < 3 ? () => handleDelete() : null}
      otherButtons={null}
      handleClose={() => handleClose()}
      handleCreate={() => handleCreate()}
      hasHelp={true}
    />
  );
};

export default KeysboardDialog;
