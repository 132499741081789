import TextInput from '../components/TextInput';
import FormButton from '../components/FormButton';
import FormWrapper from '../components/FormWrapper';
import { Button } from '@mui/material'
import { ResetPasswordFormSchema } from '../components/Validation' //UserDetailsFormSchema
import { useFormik } from 'formik';
// import { Auth } from 'aws-amplify';
import { updatePassword } from "aws-amplify/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from 'react-router-dom';
// import { useAuthenticator } from "@aws-amplify/ui-react";

function Account(props) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const { user } = useAuthenticator();

    const resetPasswordForm = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            confirmPassword: ''
        },
        enableReinitialize: true,
        validationSchema: ResetPasswordFormSchema,
        onSubmit: (values, actions) => {
            updatePassword(
                // user,
                values.currentPassword,
                values.password
            )
                .then((result) => {
                    actions.setSubmitting(false)
                    enqueueSnackbar('Your password has been updated.', { variant: "success" });
                })
                .catch((err) => {
                    actions.setSubmitting(false)
                    enqueueSnackbar(err.message, { variant: "error" });
                });
        }
    })

    // const refreshUser = () => {
    //     Auth.currentAuthenticatedUser()
    //     .catch(err => {
    //         console.log(err)
    //         enqueueSnackbar(err.message, { variant: "error" });
    //     })
    // }

    // const updateUserForm = useFormik({
    //     initialValues: {
    //         email: user?.attributes.email,
    //         phone: user?.attributes.phone_number?.slice(3)
    //     },
    //     enableReinitialize:true,
    //     validationSchema: UserDetailsFormSchema,
    //     onSubmit: (values, actions) => {
    //         console.log('UPDATING USER')
    //         Auth.updateUserAttributes(user, {
    //             email: values.email,
    //             phone_number: `+61${values.phone}`
    //         })
    //         .then((result) => {
    //             console.log(result)
    //             actions.setSubmitting(false)
    //             refreshUser()
    //             enqueueSnackbar('Your contact details have been updated.', { variant: "success" });
    //         })
    //         .catch((err) => {
    //             actions.setSubmitting(false)
    //             enqueueSnackbar(err.message, { variant: "error" });
    //         });
    //     }
    // }, [user])

    return (
        <div data-tg-tour={"Use this form to update your password"}>
            <FormWrapper formik={resetPasswordForm} header={'Reset Password'}>
                <TextInput type={'password'} label={'Current Password'} name={'currentPassword'} />
                <TextInput type={'password'} label={'New Password'} name={'password'} />
                <TextInput type={'password'} label={'Confirm Password'} name={'confirmPassword'} />
                <FormButton label={'Reset Password'} />
                <Button variant="contained" onClick={() => navigate(-1)} sx={{ backgroundImage: `linear-gradient(to right, #293443 0%, #1f2833  51%, #293443  100%)`, marginTop: 2 }}
                    color='secondary'
                    fullWidth > Back </Button>
            </FormWrapper>

            {/* <FormWrapper formik={updateUserForm} header={'Contact Details'}>
                <TextInput label={'Email'} name={'email'}/>
                <TextInput label={'Phone Number'} type={'tel'} name={'phone'}/>
                <FormButton label={'Update'} />
            </FormWrapper> */}
        </div>
    );
}

export default Account;
