import { createContext, useContext, useState } from "react";
import "@sjmc11/tourguidejs/src/scss/tour.scss" // Styles
import { TourGuideClient } from "@sjmc11/tourguidejs/src/Tour" // JS

interface TourContextValue {
    //tg: TourGuideClient| null;
    start: (group?: string) => void;
}

type TourContextProviderProps = {
    children: React.ReactNode;
};

export const TourContextProvider = ({ children }: TourContextProviderProps) => {
    const [tg] = useState(() => new TourGuideClient({ dialogZ: 1000 }));
    //tg.start();

    return <TourContext.Provider value={{
        start: (group?: string) => {
            if (group) {
                tg.start(group); // Start the specific group
            } else {
                tg.start(); // Start the default tour
            }
        },
    }}>{children}</TourContext.Provider>;
};

const TourContext = createContext<TourContextValue>({
    //tg: null,
    start: () => { },
});

export const useTourContext = () => {
    return useContext(TourContext);
};
