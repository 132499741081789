import React from 'react';
import theme from '../../styles/materialTheme';
import { useNavigate } from 'react-router-dom';

// Material
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

// Hooks
import useWindowDimensions from '../../hooks/useWindowDimensions';

// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';

function TabActionBar({ actions, activeEntityId, addItem, visibleTab }) {
  const { width: displayWidth, divideWidth } = useWindowDimensions();
  const navigate = useNavigate();

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent={'space-around'}
      width={displayWidth < divideWidth ? '100%' : '45%'}
      sx={{
        marginBottom: displayWidth < divideWidth ? '-22px' : '-38px',

      }}
    >
      <Box p={1} display='flex'>
        {actions.map((action) => (
          <Typography
            data-tg-tour={action.help}
            sx={{
              padding: 1,
              paddingRight: '15px',
              paddingLeft: '15px',
              marginBottom: addItem ? '0px' : '4px',
              marginTop: addItem ? '0px' : '4px',
              marginLeft: '7px',
              marginRight: '7px',
              borderRadius: 1,
              backgroundColor:
                visibleTab !== action.tab
                  ? theme.palette.primary.main
                  : theme.palette.info.main,
              color:
                visibleTab !== action.tab
                  ? theme.palette.info.main
                  : theme.palette.primary.main,
              '&:hover': {
                cursor: 'pointer'
              },
            }}
            onClick={() => navigate(`/${activeEntityId}/${action.tab}`)}
            key={`actionButton_${action.name}`}
          >
            {action.name.toUpperCase()}
          </Typography>
        ))}
      </Box>

      {addItem && <Box p={1}
        data-tg-tour="Click <span style='color: #ff9900'>this</span> button to add a new <a href='https://google.com'>item</a>"
      >
        <Typography
          sx={{
            padding: 1,
            paddingRight: '15px',
            paddingLeft: '15px',
            borderRadius: 1,
            backgroundColor: addItem ? theme.palette.info.main : null,
            color: addItem ? theme.palette.primary.main : null,
            '&:hover': {
              cursor: 'pointer'},
          }}
          onClick={() => { addItem && addItem() }}
        >
          <AddCircleIcon
            sx={{
              backgroundColor: addItem ? null : theme.palette.secondary.main,
              color: addItem ? null : theme.palette.secondary.main,
            }}
          />
        </Typography>
      </Box>
      }
    </Box>
  );
}

export default TabActionBar;
