import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// MATERIAL
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// ICONS
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// APIS
import { fetchDevices } from "../../../apis/devices";
import { fetchUserDevicePermissions, modifyUsersGroups } from "../../../apis/users";




const addToGroups = new Set();
const removeFromGroups = new Set();
let originalGroups = new Set();



const GroupPermissionsDialog = ({ staff, entityId, handleClose }) => {
  const [ displayEmptyGroups, setDisplayEmptyGroups ] = useState(1);

  // console.log(staff);


  const queryClient = useQueryClient();
  // Queries
  const { data: deviceList } = useQuery({
    queryKey: [ 'devices', entityId ],
    queryFn: () => fetchDevices(entityId),
  });

  const { data: userPermissions, status } = useQuery({
    queryKey: [ 'permissions', entityId, staff.userEntityId ],
    queryFn: () => fetchUserDevicePermissions(entityId, staff.userEntityId),
  });


  const [hasPermission, setHasPermission] = useState(new Set());

  useEffect(() => {
    if(userPermissions && userPermissions.length > 0) {
      originalGroups = new Set(userPermissions.map((el => el.groupId)));
      setHasPermission(new Set(originalGroups));
    }
  }, [userPermissions])

      // Mutations
  const mutation = useMutation(modifyUsersGroups, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['permissions', entityId, staff.userEntityId]);
      addToGroups.clear();
      removeFromGroups.clear();
      queryClient.invalidateQueries(['users', entityId]);
      handleClose();

    },
  });

  const applyChanges = () => {
    if(addToGroups.size > 0 || removeFromGroups.size > 0) {
      const postBody = { add: [...addToGroups], remove: [...removeFromGroups], entityId: entityId, userEntityId: staff.userEntityId };
      // console.log(postBody);

      mutation.mutate(postBody);
    } else {
      handleClose();
    }
  }

  const remove = (id) => {
    if(originalGroups.has(id)) {
      removeFromGroups.add(id);
    } else {
      addToGroups.delete(id);
    }
    setHasPermission(prev => new Set([...prev].filter(x => x !== id)));
  }

  const add = (id) => {
    if(!originalGroups.has(id)) {
      addToGroups.add(id);
    }else{
      removeFromGroups.delete(id);
    }
    setHasPermission(prev => new Set(prev.add(id)));
  }

  const groupsWithoutKeys = deviceList?.groups.flatMap((el) =>
  (el.groupTypeId > 3)
    ? []
    : [el]);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="dialog-title"
      >
        {staff.name}'s groups
      </DialogTitle>
      <DialogContent>
        {(status === 'loading' || mutation.isLoading) && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
        {/* {userPermissions && userPermissions.length > 0 ? <p>Groups</p> : <p>No permissions found</p>} */}
        <Typography display="inline">Only show groups with devices</Typography>
        <Switch checked={!!displayEmptyGroups}
  onChange={() => {setDisplayEmptyGroups((prev) => prev === 1 ? 0 : 1)}} color='primary' />
        <Grid container justifyContent='flex-start' sx={{ marginBottom: '5px' }}>
        {groupsWithoutKeys &&
          groupsWithoutKeys.map((group) => (hasPermission.has(group.groupId) && ((!displayEmptyGroups && group.deviceIds[0] === null) || (group.deviceIds[0] !== null )) &&
                <Grid xs={12} sm={6} item key={group.groupId} style={{ display: 'flex' }}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: '95%',
                      marginTop: '5px',
                      marginLeft: '5px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundImage: `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`,
                      backgroundColor: '#ff9900',
                    }}
                  >
                  <Typography sx={{ paddingLeft: '5px' }}>{group.groupName}</Typography>
                  
                    <IconButton onClick={() => remove(group.groupId)} sx={{ p: 1 }}>
                      <RemoveCircleIcon fontSize="small" />
                    </IconButton>
                    {/* <Tooltip title='Remove'></Tooltip> */}
                </Paper>
              </Grid>
              ))}
          </Grid>
          <Divider />
          <Grid container justifyContent='flex-start' sx={{ marginBottom: '5px' }}>
        {groupsWithoutKeys &&
          groupsWithoutKeys.map((group) => (!hasPermission.has(group.groupId) && ((!displayEmptyGroups && group.deviceIds[0] === null) || (group.deviceIds[0] !== null )) &&
                <Grid xs={12} sm={6} item key={group.groupId} style={{ display: 'flex' }}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: '95%',
                      marginTop: '5px',
                      marginLeft: '5px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundImage: `linear-gradient(to bottom right,#BEBEBE,#EBECF0,#BEBEBE)`,
                      backgroundColor: '#BEBEBE',
                    }}
                  >
                  <Typography sx={{ paddingLeft: '5px' }}>{group.groupName}</Typography>
                  
                    <IconButton onClick={() => add(group.groupId)} sx={{ p: 1 }}>
                      <AddCircleIcon fontSize="small" />
                    </IconButton>
                    {/* <Tooltip title='Add'></Tooltip> */}
                </Paper>
              </Grid>
              ))}
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => applyChanges()}
          style={{
            backgroundColor: "#FF9900",
            color: "#FFFFFF",
          }}
          autoFocus
        >
          {addToGroups.size > 0 || removeFromGroups.size > 0 ? 'APPLY' : 'CLOSE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupPermissionsDialog;
