import React, { useState } from 'react';
import { useTourContext } from '../../TourContext';

// COMPONENTS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';


// MATERIAL
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';


// ICONS
// import theme from '../../styles/materialTheme';
import HelpIcon from '@mui/icons-material/Help';

// APIS
import { getAnalyticsQuery } from '../../apis/entities';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

function StatisticsDialog(_props) {
  const { entity, handleClose } = _props;
  const { data: statistics, status } = getAnalyticsQuery(entity.id, entity.entityType)
  const { start } = useTourContext();


  const [dataView, setDataView] = useState(0);

  const toggleView = () => {
    setDataView((prev) => (prev === 0 ? 1 : 0));
  };

  const stackedBarChartoptions = {
    plugins: {
      title: {
        display: true,
        text: `Weekly Activations by ${dataView ? 'Device' : 'Method'}`,
      },
      colors: {
        enabled: true,
        forceOverride: true,
      },
      legend: { position: 'bottom' },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  // console.log(statistics);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='md' //xs, sm, md, lg, xl
    >
      <DialogTitle id='stats-dialog-title'>
        <Box display='flex'>
          <Box flexGrow={1}>Statistics for {entity.entityName}</Box>
          {status === 'success' && statistics.activationCount > 0 && (
            <Box>
              <Button onClick={() => toggleView()}>Toggle View</Button>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        {status === 'loading' && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
        <Grid container spacing={2}>
          {status === 'success' && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                {statistics.userData[dataView] && (
                  <Pie
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: `${dataView
                            ? entity.entityType === 'Site'
                              ? 'User Leaderboard'
                              : 'Users / Site'
                            : 'User Type'
                            }`,
                        },
                        colors: {
                          enabled: true,
                          forceOverride: true,
                        },
                      },
                    }}
                    data={statistics.userData[dataView]}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack spacing={2} alignItems={'center'}>
                  <Typography>{statistics.activationCount} Activations</Typography>
                  <Typography>{statistics.deviceCount} Devices</Typography>
                  <Typography>{statistics.userCount} Users</Typography>
                  <Typography>{statistics.integrationCount} Integrations</Typography>
                  <Typography>{statistics.downtimeData.offline} Offline</Typography>
                  <Typography>{statistics.downtimeData.disconnects} Disconnects</Typography>
                  <Typography>
                    {statistics.downtimeData.minsDowntime} Minutes Offline
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack spacing={2} alignItems={'center'}>
                  {statistics.deviceData[dataView] && (
                    <Pie
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: `${dataView
                              ? entity.entityType === 'Site'
                                ? 'Device Leaderboard'
                                : 'Devices / Site'
                              : 'Device Type'
                              }`,
                          },
                          colors: {
                            enabled: true,
                            forceOverride: true,
                          },
                        },
                      }}
                      data={statistics.deviceData[dataView]}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item sm={12}>
                <Stack spacing={2} alignItems={'center'}>
                  {statistics.activationCount > 0 && (
                    <Bar
                      options={stackedBarChartoptions}
                      data={statistics.activations[dataView]}
                    />
                  )}
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
      <HelpIcon onClick={() => start('dialogGrp')} />
        <Button
          onClick={() => handleClose()}
          style={{
            backgroundColor: '#FF9900',
            color: '#FFFFFF',
          }}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StatisticsDialog;
