import { apiGet, apiPost } from "../utils/api";

const getIntegrations = async (entityId) => {
  if(isNaN(parseInt(entityId))) return [];
  const postBody = { entityId: entityId };
  const result = await apiGet('integrations/get-integrations', postBody);
  // console.log(result);
  return result;
};

const createIntegration = async (postBody) => {
  const res =  await apiPost('integrations/create-integration', postBody);
  return res;
};

const deleteIntegration = async (postBody) => {
  const res =  await apiPost('integrations/delete-integration', postBody);
  return res;
};

export { getIntegrations, createIntegration, deleteIntegration };
