//@format
import { ApplyInvite } from '../apis/invites';
import { apiGet, apiPost } from "../utils/api";
import { signInWithRedirect, getCurrentUser } from "aws-amplify/auth";

import * as Sentry from '@sentry/react';

export const listener = async data => {
    // console.log('LISTENER ', data)
    switch (data?.payload?.event) {
        case 'customOAuthState':
            //console.log('CUSTOM DATA', data);
            const invite = data.payload.data;
            if (invite) {
                console.log('Who the what????', invite);
                await ApplyInvite(invite);
            }
            break;
        case 'signedOut':
            Sentry.setUser(null);
            window.location.replace('/');
            //console.log('Goodbye :-)');
            break;
        case 'signedIn':
            Sentry.setUser({username: data.payload.data.username});
            await apiPost('authentication/attach-policy').then(async result => {
                // console.log('Added IOT Policy', result);
            });
            break;
        default:
            console.log('“Deeds will not be less valiant because they are unpraised.” — Aragorn');
            break;
    }
};
