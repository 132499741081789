import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// LIBRARIES
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// MATERIAL
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';

// import Box from '@mui/material/Box';

// APIS
import {
  updateReservationQuery,
} from '../apis/updateReservationQuery';
import { fetchUsers } from '../../../apis/users';

//COMPONENTS
import DialogLayout from '../../../components/DialogLayout';
// import CardLayout from '../../../components/CardLayout';
import { deviceIcon } from '../../../components/CardIcons';

// FUNCTIONS
// import {
//   textfieldDateTime,
//   addToDate,
//   isoDate,
//   diffDate,
// } from '../../../functions/formatDate';

const simpleCard = (uniqueId, title, caption, remove) => {
  return (
    <Paper
      key={uniqueId}
      elevation={3}
      sx={{
        width: '100%',
        display: 'flex',
        marginBottom: '5px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundImage: `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`,
        backgroundColor: '#ff9900',
      }}
    >
      {deviceIcon('Key Store Key', true, false, true)}
      <Stack>
        <Typography>{title}</Typography>
        <Typography variant='caption'>{caption}</Typography>
      </Stack>

      <IconButton onClick={() => remove()} sx={{ p: 1 }}>
        <RemoveCircleIcon fontSize='small' />
      </IconButton>
    </Paper>
  );
};

const AllocateKeyDialog = ({ entityId, keyItem, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // console.log(keyItem, 'keyItem')

  const { data: staffList, status } = useQuery({
    queryKey: ['users', entityId],
    queryFn: () => fetchUsers(entityId),
  });

  // console.log(staffList.users.filter((u) => u.userTypeID > 4));
  console.log(staffList?.users?.length > 0 ?  staffList.users.filter((u) => u.userTypeId >= 4) : "None");

  const [user, setUser] = useState('');


  const removeKey = (key) => {
    handleClose();
  };



  const updateKeyReservationMutation = useMutation(updateReservationQuery, {
    onSuccess: () => {
      enqueueSnackbar('Success.', { variant: 'success' });
      queryClient.invalidateQueries(['keysboards', entityId]);
      handleClose();
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('Failed to update reservation', { variant: 'error' });
    },
  });


  const updateKeyReservation = async () => {

    if (user === '') {
      enqueueSnackbar('No user selected.', { variant: 'error' });
      return;
    }

    const postBody = {
        reservationId: keyItem.reservation_id,
        userEntityId: staffList.users.filter((u) => u.name === user)[0].userEntityId,
      };

    enqueueSnackbar('Updating reservation.', { variant: 'info' });
    // console.log(staffList.users, postBody);
    updateKeyReservationMutation.mutate(postBody);
  };

  const dialogContent = (
    <>
      {simpleCard(keyItem.unique_id, keyItem.name, keyItem.address, () => removeKey(keyItem))}
      {/* <Box display='flex' alignItems='center'>
        <AddCircleIcon />
      </Box> */}
      {staffList?.users && staffList.users.length > 0 && (
        <Autocomplete
          id='user_for_reservation'
          name='userId'
          inputValue={user}
          onInputChange={(event, newInputValue) => {
            setUser(newInputValue);
          }}
          options={staffList.users.filter((u) => u.userTypeId >= 3)}
          getOptionLabel={(option) => option.name}
          sx={{ width: '100%', paddingTop: '5px' }}
          renderInput={(params) => (
            <TextField
              inputProps={{
                autoComplete: 'none',
              }}
              {...params}
              label='User'
            />
          )}
        />
      )}
    </>
  );

  return (
    <DialogLayout
      dialogTitle={`Allocate Key`}
      dialogContent={dialogContent}
      handleDelete={null}
      otherButtons={null}
      handleClose={() => handleClose()}
      handleCreate={() => updateKeyReservation()}
    />
  );
};

export default AllocateKeyDialog;
