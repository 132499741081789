import React, { useState } from 'react';
// import { API } from 'aws-amplify';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

// MATERIAL
// import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// ICONS

// COMPONENTS
import DeviceDialog from '../dialogs/DeviceDialog';
import QRDialog from '../dialogs/QRDialog';
import CardLayout from '../../../components/CardLayout';
import LogDialog from '../dialogs/LogDialog';
import ShareDialog from '../dialogs/ShareDialog';
import { deviceIcon } from '../../../components/CardIcons';

// APIS
import { modifyDeviceGroups, restoreDevice } from '../../../apis/devices';
import { openDevice, provisionDevice } from '../apis/devices';
import { enqueueSnackbar } from 'notistack';

const commonMenuItems = ['Logs', 'Share', 'QR', 'Copy'];
let groupAction;

export default function CardDevice(_props) {
  const { device, groupId, entity, currentView, groupToExpand, removable = true } = _props;
  // console.log(device)

  const { data: deviceList } = useQuery({
    queryKey: ['devices', entity.id],
  });

  const [anchorDeviceMenu, setAnchorDeviceMenu] = useState(null);
  const [anchorGroupMenu, setAnchorGroupMenu] = useState(null);

  const handleOpenDeviceMenu = (event) => {
    setAnchorDeviceMenu(event.currentTarget);
  };

  const handleOpenGroupMenu = (event) => {
    setAnchorGroupMenu(anchorDeviceMenu);
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(modifyDeviceGroups, {
    onSuccess: () => {
      // Invalidate and refetch
      enqueueSnackbar('Device modified', { variant: 'success' });
      queryClient.invalidateQueries(['devices', entity.id]);
    },
    onError: (error) => {
      enqueueSnackbar('Error modifying device', { variant: 'error' });
      console.log(error);
    },
  });

  const handleMoveAndCopyAPI = async (endpoint, deviceBody) => {
    // console.log(deviceBody,endpoint);
    const postBody = {
      body: deviceBody, // REQUIRED as API handler expects 'body' key
      endpoint: endpoint,
    };
    mutation.mutate(postBody);
  };

  const restoreMutation = useMutation(restoreDevice, {
    onSuccess: () => {
      // Invalidate and refetch
      enqueueSnackbar('Device restored', { variant: 'success' });
      queryClient.invalidateQueries(['devices', entity.id]);
    },
    onError: (error) => {
      enqueueSnackbar('Error restoring device', { variant: 'error' });
      console.log(error);
    },
  });

  const handleRestore = () => {
    // console.log(deviceBody,endpoint);
    const postBody = {
        deviceId: device.id,
      };
    restoreMutation.mutate(postBody);
  };


  const [openDialog, setOpenDialog] = useState(null);

  const exportProvisionData = async () => {
    const postBody = { id: device.id };
    // const result = await API.get('Scanseqr', 'devices/provision', postBody);
    const result = await provisionDevice(postBody);
    // console.log(result)

    if (!result || !result.devices) {
      enqueueSnackbar('API Error exporting provision data', { variant: 'error' });
      return;
    }
    
    const blob = new Blob([JSON.stringify(result.devices)], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${device.name}-provision.json`;
    link.href = url;
    link.click();
    enqueueSnackbar('Device provision data downloaded', { variant: 'success' });
  };

  const handleCloseDeviceMenu = async (menuItem) => {
    groupAction = menuItem;
    console.log(device);
    switch (menuItem) {
      case 'Activate':
        setAnchorDeviceMenu(null);
        const postBody = {
            deviceId: device.id,
            deviceUuid: device.shortId,
            entityId: entity.id,
            lat: entity.lat,
            lng: entity.lng,
            method: 'Manager Activation',
          };

        // const res = await API.post('Scanseqr', `devices/open`, postBody);
        const res = await openDevice(postBody);
        console.log(res);
        if (res && res.success) {
          if (res.result === 'Granted') {
            enqueueSnackbar('Device Activated', { variant: 'success' });
          } else {
            enqueueSnackbar('Device Activation Failed', { variant: 'error' });
          }
        } else {
          enqueueSnackbar('API Error activating device', { variant: 'error' });
        }
        // console.log(
        //   '[' + new Date().toISOString().substring(11, 23) + '] - API responce',
        //   res
        // );
        break;
      case 'Logs':
        setAnchorDeviceMenu(null);
        setOpenDialog('LOG_DIALOG');
        break;
      case 'Share':
        console.log('SHARE_DIALOG');
        setAnchorDeviceMenu(null);
        setOpenDialog('SHARE_DIALOG');
        break;
      case 'QR':
        setAnchorDeviceMenu(null);
        // console.log(`scanseqr.com/${device.url}/${device.uuid.split('-')[0]}`);
        setOpenDialog('QR_DIALOG');
        break;
      case 'Edit':
        setAnchorDeviceMenu(null);
        setOpenDialog('EDIT_DIALOG');
        break;
      case 'Move':
        handleOpenGroupMenu();
        break;
      case 'Copy':
        handleOpenGroupMenu();
        break;
      case 'Remove':
        // console.log(menuItem, device.id, device.name, groupId, groups);
        if (device && device.id && groupId) {
          // console.log(`Remove ${device.id} from group ${groupId}`);
          handleMoveAndCopyAPI('remove-device', { groupId: groupId, deviceId: device.id });
        }
        setAnchorDeviceMenu(null);
        break;
      case 'Provision':
        setAnchorDeviceMenu(null);
        exportProvisionData();
        // console.log('DOWNLOAD JSON');
        break;
      case 'DeviceUUID':
        navigator.clipboard.writeText(device.uuid);
        enqueueSnackbar('Copied device identifier.', { variant: 'success' });
        break;
      case 'Restore':
        setAnchorDeviceMenu(null);
        handleRestore();
        break;
      default:
        // console.log(menuItem);
        setAnchorDeviceMenu(null);
        break;
    }
  };

  const handleCloseGroupMenu = async (menuItem) => {
    setAnchorGroupMenu(null);
    setAnchorDeviceMenu(null);
    // console.log(menuItem, device.id, device.name, groupId, groups);
    if (menuItem && menuItem.groupId && device && device.id) {
      groupToExpand(menuItem.groupId);
      if (groupAction === 'Move' && groupId) {
        // console.log(`Move ${device.id} from group ${groupId} to group ${menuItem.groupId}`);
        handleMoveAndCopyAPI('move-device', {
          groupId: menuItem.groupId,
          oldGroupId: groupId,
          deviceId: device.id,
        });
      } else if (groupAction === 'Copy') {
        // console.log(`Copy ${device.id} to group ${menuItem.groupId}`);
        handleMoveAndCopyAPI('copy-device', {
          groupId: menuItem.groupId,
          deviceId: device.id,
        });
      }
    } else {
      console.log('ERROR: No group or device selected');
    }
    groupAction = null;
  };

  const deviceMenu = () => {
    return (
      <>
        {device.deletedTime === null ? (
          <Menu
            sx={{ mt: '25px' }}
            id='menu-appbar'
            anchorEl={anchorDeviceMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorDeviceMenu)}
            onClose={handleCloseDeviceMenu}
          >
            <MenuItem key={'Activate'} onClick={() => handleCloseDeviceMenu('Activate')}>
              <div
                data-activate-device-id={device.uuid}
                data-activate-device-name={device.name}
              >
                <Typography textAlign='center'>Activate</Typography>
              </div>
            </MenuItem>
            {/* )} */}
            {device.deviceType !== 'Key Store Key' && (
              <div
                data-edit-device-id={device.uuid}
                data-edit-device-name={device.name}
              >
                <MenuItem key={'Edit'} onClick={() => handleCloseDeviceMenu('Edit')}>
                  <Typography textAlign='center'>Edit</Typography>
                </MenuItem>
              </div>
            )}
            {commonMenuItems.map((menuItem) => (
              <MenuItem key={menuItem} onClick={() => handleCloseDeviceMenu(menuItem)}>
                <Typography textAlign='center'>{menuItem}</Typography>
              </MenuItem>
            ))}
            {removable && (
              <div
                data-move-device-id={device.uuid}
                data-move-device-name={device.name}
              >
                <MenuItem key={'Move'} onClick={() => handleCloseDeviceMenu('Move')}>
                  <Typography textAlign='center'>Move</Typography>
                </MenuItem>
              </div>
            )}
            {device.instances > 1 && device.groupId == null && removable && (
              <div
                data-remove-device-id={device.uuid}
                data-remove-device-name={device.name}
              >
                <MenuItem key={'Remove'} onClick={() => handleCloseDeviceMenu('Remove')}>
                  <Typography textAlign='center'>Remove</Typography>
                </MenuItem>
              </div>
            )}
            {entity.userTypeId <= 3 && device.deviceType !== 'Key Store Key' && (
              <div
                data-provision-device-id={device.uuid}
                data-provision-device-name={device.name}
              >
                <MenuItem key={'Provision'} onClick={() => handleCloseDeviceMenu('Provision')}>
                  <Typography textAlign='center'>Provision</Typography>
                </MenuItem>
                <MenuItem key={'DeviceUUID'} onClick={() => handleCloseDeviceMenu('DeviceUUID')}>
                  <Typography textAlign='center'>Device Identifier</Typography>
                </MenuItem>
              </div>
            )}
          </Menu>
        ) : (
          <Menu
            sx={{ mt: '25px' }}
            id='menu-appbar'
            anchorEl={anchorDeviceMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorDeviceMenu)}
            onClose={handleCloseDeviceMenu}
          >
            <MenuItem key={'Restore'} onClick={() => handleCloseDeviceMenu('Restore')}>
              <Typography textAlign='center'>Restore</Typography>
            </MenuItem>
          </Menu>
        )}

        <Menu
          sx={{ mt: '25px', ml: '24px' }}
          id='menu-appbar'
          anchorEl={anchorGroupMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorGroupMenu)}
          onClose={handleCloseGroupMenu}
        >
          {deviceList.groups.flatMap((menuItem) =>
            menuItem.groupId === groupId || menuItem.groupTypeId > 3
              ? []
              : [
                  <MenuItem
                    key={menuItem.groupId}
                    onClick={() => handleCloseGroupMenu(menuItem)}
                  >
                    <Typography textAlign='center'>{menuItem.groupName}</Typography>
                  </MenuItem>,
                ]
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
      <CardLayout
        data={{ 'device-id': device.uuid, 'device-name': device.name }}
        onClickFunction={null}
        icon={deviceIcon(
          device.deviceType,
          device.inputState,
          device.outputState,
          device.status
        )}
        active={device.deletedTime === null ? device.status || false : null}
        text={device.name}
        caption={currentView === 'List View' && device.groupName}
        handleOpenMenu={handleOpenDeviceMenu}
        menu={deviceMenu()}
      />
      {openDialog === 'LOG_DIALOG' && (
        <LogDialog
          deviceName={device.name}
          deviceID={device.id}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'SHARE_DIALOG' && (
        <ShareDialog
          deviceName={device.name}
          deviceID={device.id}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'QR_DIALOG' && (
        <QRDialog qrData={`${import.meta.env.VITE_APP_URL}${device.url}/${device.uuid.substring(0,8)}`} itemName={device.name} handleClose={() => setOpenDialog(null)} />
      )}
      {openDialog === 'EDIT_DIALOG' && (
        <DeviceDialog
          title='Edit'
          deviceId={device.id}
          entity={entity}
          handleClose={() => setOpenDialog(null)}
          groupId={groupId}
        />
      )}
    </>
  );
}
