import { apiGet } from "../../../utils/api";
import { useQuery } from '@tanstack/react-query';

const fetchKeylogs = async (keyId) => {
  if (isNaN(parseInt(keyId))) return [];
  const postBody = { keyId: keyId };
  // const result = await API.get('Scanseqr', 'keysboard/get-entity-keysboards', postBody);
  const result = await apiGet('keysboard/get-key-logs', postBody);
  console.log(postBody, result);
  return result.keyboards;
};

export const useKeyLogsQuery = (keyId) =>
  useQuery({
    queryKey: ['keylogs', keyId],
    queryFn: () => fetchKeylogs(keyId),
  });