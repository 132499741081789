import React, { useState } from 'react';
// import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from '@tanstack/react-query'; //useQuery

// MATERIAL
// import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import theme from '../../../styles/materialTheme';

// ICONS
import KeyIcon from '@mui/icons-material/Key'; // Key

// COMPONENTS
import DeviceDialog from '../dialogs/DeviceDialog';
import QRDialog from '../dialogs/QRDialog';
import LogDialog from '../dialogs/LogDialog';
import ShareDialog from '../dialogs/ShareDialog';

// APIS
import { modifyDeviceGroups } from '../../../apis/devices';
import { openDevice, provisionDevice } from '../apis/devices';

// HOOKS
// import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { CardGrid } from '../../../components/CardGrid';
import CardDevice from './CardDevice';

const commonMenuItems = ['Logs', 'QR']; //['Add Key', 'Logs', 'Share', 'QR', 'Copy'];
let groupAction;

export default function CardKeyStore(_props) {
  const { device, groups, entity, groupToExpand } = _props;

  // const { width: displayWidth, divideWidth } = useWindowDimensions();

  const [anchorDeviceMenu, setAnchorDeviceMenu] = useState(null);
  const [anchorGroupMenu, setAnchorGroupMenu] = useState(null);

  const handleOpenDeviceMenu = (event) => {
    console.log(event);
    setAnchorDeviceMenu(event.currentTarget);
  };

  const handleOpenGroupMenu = (event) => {
    setAnchorGroupMenu(anchorDeviceMenu);
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(modifyDeviceGroups, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['devices', entity.id]);
    },
  });

  const handleMoveAndCopyAPI = async (endpoint, deviceBody) => {
    // console.log(deviceBody,endpoint);
    const postBody = {
      body: deviceBody,
      endpoint: endpoint,
    };
    mutation.mutate(postBody);
  };

  const [openDialog, setOpenDialog] = useState(null);

  const exportProvisionData = async () => {
    const postBody = { id: device.id };
    const result = await API.get('Scanseqr', 'devices/provision', postBody);
    // console.log(result)

    const blob = new Blob([JSON.stringify(result.devices)], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${device.name}-provision.json`;
    link.href = url;
    link.click();
  };

  const handleCloseDeviceMenu = async (menuItem) => {
    groupAction = menuItem;
    switch (menuItem) {
      case 'Activate':
        setAnchorDeviceMenu(null);
        const postBody = {
            deviceId: device.shortId,
            entityId: entity.id,
            lat: entity.lat,
            lng: entity.lng,
            method: 'Manager Activation',
          };
        // console.log(postBody);
        // const res = await API.post('Scanseqr', `devices/open`, postBody);
        const res = await openDevice(postBody);
        console.log(
          '[' + new Date().toISOString().substring(11, 23) + '] - API responce',
          res
        );
        break;
      case 'Add Key':
        setAnchorDeviceMenu(null);
        setOpenDialog('KEY_DIALOG');
        break;
      case 'Logs':
        setAnchorDeviceMenu(null);
        setOpenDialog('LOG_DIALOG');
        break;
      case 'Share':
        console.log('SHARE_DIALOG');
        setAnchorDeviceMenu(null);
        setOpenDialog('SHARE_DIALOG');
        break;
      case 'QR':
        setAnchorDeviceMenu(null);
        // console.log(`scanseqr.com/${device.url}/${device.uuid.split('-')[0]}`);
        setOpenDialog('QR_DIALOG');
        break;
      case 'Edit':
        setAnchorDeviceMenu(null);
        setOpenDialog('EDIT_DIALOG');
        break;
      case 'Move':
        handleOpenGroupMenu();
        break;
      case 'Copy':
        handleOpenGroupMenu();
        break;
      case 'Remove':
        // console.log(menuItem, device.id, device.name, groupId, groups);
        if (device && device.id && device.groupId) {
          // console.log(`Remove ${device.id} from group ${groupId}`);
          handleMoveAndCopyAPI('remove-device', {
            groupId: device.groupId,
            deviceId: device.id,
          });
        }
        setAnchorDeviceMenu(null);
        break;
      case 'Provision':
        setAnchorDeviceMenu(null);
        exportProvisionData();
        // console.log('DOWNLOAD JSON');
        break;
      default:
        // console.log(menuItem);
        setAnchorDeviceMenu(null);
        break;
    }
  };

  const handleCloseGroupMenu = async (menuItem) => {
    setAnchorGroupMenu(null);
    setAnchorDeviceMenu(null);
    // console.log(menuItem, device.id, device.name, groupId, groups);
    if (menuItem && menuItem.groupId && device && device.id) {
      groupToExpand(menuItem.groupId);
      if (groupAction === 'Move' && device.groupId) {
        // console.log(`Move ${device.id} from group ${groupId} to group ${menuItem.groupId}`);
        handleMoveAndCopyAPI('move-device', {
          groupId: menuItem.groupId,
          oldGroupId: device.groupId,
          deviceId: device.id,
        });
      } else if (groupAction === 'Copy') {
        // console.log(`Copy ${device.id} to group ${menuItem.groupId}`);
        handleMoveAndCopyAPI('copy-device', {
          groupId: menuItem.groupId,
          deviceId: device.id,
        });
      }
    } else {
      console.log('ERROR: No group or device selected');
    }
    groupAction = null;
  };

  const deviceMenu = () => {
    return (
      <>
        <Menu
          sx={{ mt: '25px' }}
          id='menu-appbar'
          anchorEl={anchorDeviceMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorDeviceMenu)}
          onClose={handleCloseDeviceMenu}
        >
          {device.status && (
            <MenuItem key={'Activate'} onClick={() => handleCloseDeviceMenu('Activate')}>
              <Typography textAlign='center'>Activate</Typography>
            </MenuItem>
          )}
          {device.deviceTypeId !== 9 && (
            <MenuItem key={'Edit'} onClick={() => handleCloseDeviceMenu('Edit')}>
              <Typography textAlign='center'>Edit</Typography>
            </MenuItem>
          )}
          {commonMenuItems.map((menuItem) => (
            <MenuItem key={menuItem} onClick={() => handleCloseDeviceMenu(menuItem)}>
              <Typography textAlign='center'>{menuItem}</Typography>
            </MenuItem>
          ))}
          {device.instances > 1 && (
            <MenuItem key={'Remove'} onClick={() => handleCloseDeviceMenu('Remove')}>
              <Typography textAlign='center'>Remove</Typography>
            </MenuItem>
          )}
          {entity.userTypeId <= 3 && device.deviceTypeId !== 9 && (
            <MenuItem key={'Provision'} onClick={() => handleCloseDeviceMenu('Provision')}>
              <Typography textAlign='center'>Provision</Typography>
            </MenuItem>
          )}
        </Menu>
        <Menu
          sx={{ mt: '25px', ml: '24px' }}
          id='menu-appbar'
          anchorEl={anchorGroupMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorGroupMenu)}
          onClose={handleCloseGroupMenu}
        >
          {groups.flatMap((menuItem) =>
            menuItem.groupId === device.groupId || menuItem.groupTypeId > 3
              ? []
              : [
                  <MenuItem
                    key={menuItem.groupId}
                    onClick={() => handleCloseGroupMenu(menuItem)}
                  >
                    <Typography textAlign='center'>{menuItem.groupName}</Typography>
                  </MenuItem>,
                ]
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
    <Paper sx={{marginBottom: '10px'}}>
      <Box
        display='flex'
        alignItems='center'
        // width={displayWidth < divideWidth ? '100%' : '45%'}
        sx={{
          borderRadius: 1,
          backgroundImage: device.status
            ? `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`
            : `linear-gradient(to bottom right,#AAA,#FFF,#AAA)`,
          backgroundColor: '#ff9900',
        }}
      >
        <Box
          sx={{
            padding: 1,
          }}
        >
          <Icon sx={{ transform: 'rotate(90deg)' }}>
            <KeyIcon
              sx={{
                width: 25,
                height: 25,
                color: device.status ? '#FFFFFF' : theme.palette.secondary.main,
              }}
            />
          </Icon>
        </Box>
        <Box flexGrow={1}>
          <Typography
            sx={{
              color: theme.palette.secondary.main,
            }}
          >
            {device.name}
          </Typography>
        </Box>

        <Box p={1}>
          <IconButton onClick={handleOpenDeviceMenu} sx={{ p: 1 }}>
            <MoreHorizIcon />
          </IconButton>
          {deviceMenu()}
        </Box>




      </Box>
      <Box p={1}>
        {CardGrid(
      device.keys.map((el) => (
        <CardDevice
          key={el.id}
          device={el}
          groupId={el.groupId}
          entity={entity}
          currentView={'Group View'}
          groupToExpand={(id) => groupToExpand(id)}
          removable={false}
        />
      ))
    )}
    </Box>
      </Paper>

      {openDialog === 'LOG_DIALOG' && (
        <LogDialog
          deviceName={device.name}
          deviceID={device.id}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'SHARE_DIALOG' && (
        <ShareDialog
          deviceName={device.name}
          deviceID={device.id}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'QR_DIALOG' && (
        <QRDialog qrData={`${import.meta.env.VITE_APP_URL}${device.url}/${device.uuid.substring(0,8)}`} itemName={device.name} handleClose={() => setOpenDialog(null)} />
      )}
      {openDialog === 'EDIT_DIALOG' && (
        <DeviceDialog
          title='Edit'
          deviceId={device.id}
          entity={entity}
          handleClose={() => setOpenDialog(null)}
          groupId={device.groupId}
        />
      )}
    </>
  );
}
