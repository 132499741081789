import { apiGet } from "../../../utils/api";
import { useQuery } from '@tanstack/react-query';

const fetchKeysboards = async (entityId) => {
  // console.log("HIThere", entityId)
  if (isNaN(parseInt(entityId))) return [];
  const postBody = { entityId: entityId };
  // const result = await API.get('Scanseqr', 'keysboard/get-entity-keysboards', postBody);
  const result = await apiGet('keysboard/get-entity-keysboards', postBody);
  // console.log(postBody, result);
  return result.keyboards;
};

export const useKeysboardsQuery = (entityId) =>
  useQuery({
    queryKey: ['keysboards', entityId],
    queryFn: () => fetchKeysboards(entityId),
  });