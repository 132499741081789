import { apiGet, apiPost } from '../utils/api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

// FETCH ENTITIES QUERY
const fetchEntities = async () => {
  const result = await apiGet('entities/get-users-entities');
  return result || [];
};

export const fetchEntitiesQuery = () =>
  useQuery({
    queryKey: ['entities'],
    queryFn: () => fetchEntities(),
  });



// CREATE ENTITY MUTATION
const createEntity = async (postBody) => {
  const res = await apiPost('entities/create-entity', postBody);
  // await Auth.currentAuthenticatedUser({ bypassCache: true });
  return res;
};

export const createEntityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['entities']);
    },
  });
};



const getAnalytics = async (entityId, entityType) => {
  if (isNaN(parseInt(entityId))) {
    console.log('getAnalytics: invalid entityId');
    return [];
  }
  const postBody = { entity_id: entityId, entity_type: entityType };
  const res = await apiGet('entities/get-analytics', postBody);
  // console.log(res);
  return res;
};


export const getAnalyticsQuery = (entityId, entityType) =>
  useQuery({
    queryKey: ['statistics', entityId],
    queryFn: () => getAnalytics(entityId, entityType),
  });


