import React, { useState } from 'react';

// MATERIAL
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// ICONS

// COMPONENTS
import CardLayout from './CardLayout';
import { entityIcon } from './CardIcons';
import IntegrationDialog from './Dialogs/IntegrationDialog';
import ModulesDialog from './Dialogs/ModulesDialog';
import StatisticsDialog from './Dialogs/StatisticsDialog';


export default function CardEntity(props) {
  const { entity, onClickFunction } = props;
  const [anchorEntityMenu, setAnchorEntityMenu] = useState(null);

  const handleOpenEntityMenu = (event) => {
    event.stopPropagation();
    setAnchorEntityMenu(event.currentTarget);
  };

  const [openDialog, setOpenDialog] = useState(null);

  const commonMenuItems = ['Statistics', 'Integrations', 'Modules'];
  // REMOVED Modules from menu until it's ready!
  const menuItems = commonMenuItems; //entity.entityType === 'Site' ? [...commonMenuItems, 'Modules'] : commonMenuItems; 
  // const menuItems = [{name: 'Integrations', clickFunction: setOpenDialog('INTEGRATIONS')}]; //'Edit',



  const handleCloseEntityMenu = async (e, menuItem) => {
    e.stopPropagation();
    setAnchorEntityMenu(null);
    switch (menuItem) {
      case 'Edit':
        setOpenDialog('EDIT');
        console.log('EDIT');
        break;
      case 'Integrations':
        setOpenDialog('INTEGRATIONS');
        console.log('EDIT');
        break;
      case 'Statistics':
        setOpenDialog('STATISTICS');
        break;
      case 'Modules':
        setOpenDialog('MODULES'); // TODO: Add modules dialog
        break;
      // case 'Delete':
      //   const postBody = { inviteId: staff.id, entityId: entity.id };
      //   console.log('DELETE INVITE: ', postBody);
      //   deleteInviteMutation.mutate(postBody);
      //   break;
      default:
        // console.log(menuItem);
        break;
    }
  };

  const entityMenu = () => {
    return (
      <Menu
        sx={{ mt: '25px' }}
        id='menu-appbar'
        anchorEl={anchorEntityMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEntityMenu)}
        onClose={handleCloseEntityMenu}
      >
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem} onClick={(e) => handleCloseEntityMenu(e, menuItem)}>
            <Typography textAlign='center'>{menuItem}</Typography>
          </MenuItem>
        ))}
      </Menu>
    );
  };
  return (
    <>
    <CardLayout onClickFunction={onClickFunction} active={true} icon={entityIcon(entity.entityType)} text={entity.entityName} handleOpenMenu={handleOpenEntityMenu} menu={entityMenu()} allowHover={true}/>
    {openDialog === 'INTEGRATIONS' && (
      <IntegrationDialog
        title={entity.entityName}
        entity={entity}
        handleClose={() => setOpenDialog(null)}
      />
    )}
    {openDialog === 'STATISTICS' && (
      <StatisticsDialog
        entity={entity}
        handleClose={() => setOpenDialog(null)}
      />
    )}
        {openDialog === 'MODULES' && (
      <ModulesDialog
        entity={entity}
        handleClose={() => setOpenDialog(null)}
      />
    )}
    </>
  );
}