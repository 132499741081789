import React, { useState } from 'react';

// LIBRARIES
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// MATERIAL
import TextField from '@mui/material/TextField';

// COMPONENETS
import DialogLayout from '../../../components/DialogLayout';

// ICONS

// APIS
// import { createNewKeyQuery } from '../apis/createNewKeyQuery';
import { updateKeyQuery } from '../apis/updateKeyQuery';

const EditKeyDialog = ({ keyData, keysboardName, entityId, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  console.log(keyData);

  const [state, setState] = useState({
    name: keyData.name || '',
    address: keyData.address || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const queryClient = useQueryClient();

  const updateKeyMutation = useMutation(updateKeyQuery, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['keysboards', entityId]);
      handleClose();
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('Failed to create key', { variant: 'error' });
    },
  });

  const createNewKey = async () => {
    const postBody = {
        ...state,
        keyId: keyData.id,
      };
    // console.log(postBody);
    updateKeyMutation.mutate(postBody);
  };

  const dialogContent = (
    <>
      <TextField
        label='Name'
        value={state.name || ''}
        type='text'
        onChange={handleChange}
        name='name'
        fullWidth
        variant='standard'
      />
      <TextField
        label='Address'
        multiline
        value={state.address || ''}
        type='text'
        onChange={handleChange}
        name='address'
        fullWidth
        variant='standard'
      />
    </>
  );

  return (
    <DialogLayout
      dialogTitle={`Edit key in ${keysboardName}`}
      dialogContent={dialogContent}
      handleDelete={null}
      otherButtons={null}
      handleClose={() => handleClose()}
      handleCreate={() => createNewKey()}
    />
  );
};

export default EditKeyDialog;
