import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// LIBRARIES
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

// MATERIAL
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';

import Box from '@mui/material/Box';

// APIS
import {
  createReservationQuery,
} from '../apis/createReservationQuery';
import { fetchUsers } from '../../../apis/users';

//COMPONENTS
import DialogLayout from '../../../components/DialogLayout';
// import CardLayout from '../../../components/CardLayout';
import { deviceIcon } from '../../../components/CardIcons';

// FUNCTIONS
import {
  textfieldDateTime,
  addToDate,
  isoDate,
  diffDate,
} from '../../../functions/formatDate';

const simpleCard = (uniqueId, title, caption, remove) => {
  return (
    <Paper
      key={uniqueId}
      elevation={3}
      sx={{
        width: '100%',
        display: 'flex',
        marginBottom: '5px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundImage: `linear-gradient(to bottom right,#ff9900,#ffc267,#ff9900)`,
        backgroundColor: '#ff9900',
      }}
    >
      {deviceIcon('Key Store Key', true, false, true)}
      <Stack>
        <Typography>{title}</Typography>
        <Typography variant='caption'>{caption}</Typography>
      </Stack>

      <IconButton onClick={() => remove()} sx={{ p: 1 }}>
        <RemoveCircleIcon fontSize='small' />
      </IconButton>
    </Paper>
  );
};

const ReservationDialog = ({ entityId, keyItem, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: staffList, status } = useQuery({
    queryKey: ['users', entityId],
    queryFn: () => fetchUsers(entityId),
  });

  const [state, setState] = useState({
    keys: keyItem ? [keyItem] : [],
    startTime: textfieldDateTime(),
    endTime: textfieldDateTime(addToDate(new Date(), 8, 'hours')), //dayjs().add(2, 'hour').format("YYYY-MM-DDTHH:mm"),
    activationTimeout: 5,
    userId: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const removeKey = (key) => {
    setState((prevState) => ({
      ...prevState,
      keys: prevState.keys.filter((k) => k.id !== key.id),
    }));
  };



  const createKeyReservationMutation = useMutation(createReservationQuery, {
    onSuccess: () => {
      enqueueSnackbar('Success.', { variant: 'success' });
      handleClose();
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('Failed to create reservation', { variant: 'error' });
    },
  });


  const createKeyReservation = async () => {
    if (diffDate(state.startTime, state.endTime, 'minutes') > 0) {
      enqueueSnackbar('Start time must be before end time.', { variant: 'error' });
      return;
    }
    if (state.keys.length === 0) {
      enqueueSnackbar('No keys selected.', { variant: 'error' });
      return;
    }
    if (state.userId === '') {
      enqueueSnackbar('No user selected.', { variant: 'error' });
      return;
    }
    const postBody = {
        ...state,
        keys: state.keys.map((k) => k.id),
        startTime: isoDate(state.startTime), //dayjs(state.startTime).toISOString(),
        endTime: isoDate(state.endTime), //dayjs(state.endTime).toISOString(),
        userId: staffList.users.filter((u) => u.name === state.userId)[0].id,
      };

    enqueueSnackbar('Creating reservation.', { variant: 'info' });
    // console.log(postBody);
    createKeyReservationMutation.mutate(postBody);
  };

  const dialogContent = (
    <>
      {state.keys.length > 0 &&
        state.keys.map((k) => simpleCard(k.unique_id, k.name, k.address, () => removeKey(k)))}
      {/* <Box display='flex' alignItems='center'>
        <AddCircleIcon />
      </Box> */}

      <Box display='flex' alignItems='center'>
        <Typography sx={{ flexGrow: 1 }}>From: </Typography>
        <TextField
          inputProps={{
            step: 900, // 15 min
          }}
          defaultValue={state.startTime}
          type='datetime-local'
          onChange={handleChange}
          name='startTime'
        />
      </Box>
      <Box display='flex' alignItems='center'>
        <Typography sx={{ flexGrow: 1 }}>To:</Typography>
        <TextField
          defaultValue={state.endTime}
          type='datetime-local'
          onChange={handleChange}
          name='endTime'
          inputProps={{
            step: 900, // 15 min
          }}
        />
      </Box>
      {staffList?.users && staffList.users.length > 0 && (
        <Autocomplete
          id='user_for_reservation'
          name='userId'
          inputValue={state.userId}
          onInputChange={(event, newInputValue) => {
            setState((prevState) => ({
              ...prevState,
              userId: newInputValue,
            }));
          }}
          options={staffList.users}
          getOptionLabel={(option) => option.name}
          sx={{ width: '100%', paddingTop: '5px' }}
          renderInput={(params) => (
            <TextField
              inputProps={{
                autoComplete: 'none',
              }}
              {...params}
              label='User'
            />
          )}
        />
      )}
    </>
  );

  return (
    <DialogLayout
      dialogTitle={`Key Reservation`}
      dialogContent={dialogContent}
      handleDelete={null}
      otherButtons={null}
      handleClose={() => handleClose()}
      handleCreate={() => createKeyReservation()}
    />
  );
};

export default ReservationDialog;
