import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// MATERIAL
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// ICONS

// COMPONENTS
import StaffDialog from './StaffDialog.js';
import GroupPermissionsDialog from './GroupPermissionsDialog';
import CardLayout from '../../../components/CardLayout';
import LogDialog from './LogDialog';
import QRDialog from '../../devices/dialogs/QRDialog.js';
import { staffIcon } from '../../../components/CardIcons';

// APIS
import { inviteQueryMutation } from '../apis/invites.js';

// FUNCTIONS
import { diffDate } from '../../../functions/formatDate.js';

const commonMenuItems = ['Groups', 'Edit', 'Logs'];

export default function CardStaff(_props) {
  const { staff, entity, type } = _props;

  // console.log(staff, entity);

  const [anchorStaffMenu, setAnchorStaffMenu] = useState(null);

  const queryClient = useQueryClient();
  // Queries


    // Mutations
  const approveInviteMutation = inviteQueryMutation('approve', entity.id);
  const extendInviteMutation = inviteQueryMutation('extend', entity.id);
  const deleteInviteMutation = inviteQueryMutation('delete', entity.id);


  const handleOpenStaffMenu = (event) => {
    setAnchorStaffMenu(event.currentTarget);
  };

  const menuItems = () => {
    if (type === 'INVITE') return ['View QR', 'Extend', 'Delete']; //'Resend',
    if (type === 'NONUSER') return ['Invite', 'Delete']; //'Resend',
    if (type === 'APPROVAL') return ['Approve', 'Delete'];
    if (entity.entityType === 'Site') return commonMenuItems;
    return ['Edit'];
  };

  const [openDialog, setOpenDialog] = useState(null);

  const handleCloseStaffMenu = async (menuItem) => {
    setAnchorStaffMenu(null);
    switch (menuItem) {
      case 'Groups':
        setOpenDialog('PERMISSIONS');
        break;
      case 'Edit':
        setOpenDialog('EDIT');
        break;
      case 'Logs':
        setOpenDialog('LOG_DIALOG');
        break;
      case 'View QR':
        setOpenDialog('QR_DIALOG');
        break;
      case 'Extend':
        const extendPostBody = { inviteId: staff.id, entityId: entity.id };
        console.log('EXTEND INVITE: ', extendPostBody);
        extendInviteMutation.mutate(extendPostBody);
        break;
      case 'Approve':
        const approvePostBody = {inviteId: staff.id, entityId: entity.id };
        console.log('APPROVE INVITE: ', approvePostBody);
        approveInviteMutation.mutate(approvePostBody);
        break;
      case 'Delete':
        const postBody = {inviteId: staff.id, entityId: entity.id };
        console.log('DELETE INVITE: ', postBody);
        deleteInviteMutation.mutate(postBody);
        break;
      default:
        // console.log(menuItem);
        break;
    }
  };

  const staffMenu = () => {
    return (
      <Menu
        sx={{ mt: '25px' }}
        id='menu-appbar'
        anchorEl={anchorStaffMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorStaffMenu)}
        onClose={handleCloseStaffMenu}
      >
        {menuItems().map((menuItem) => (
          <MenuItem key={menuItem} onClick={() => handleCloseStaffMenu(menuItem)}>
            <Typography textAlign='center'>{menuItem}</Typography>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <CardLayout
        onClickFunction={null}
        icon={staffIcon(staff.userType)}
        active={
          staff.expired &&
          diffDate(new Date(staff.expired), new Date()) < 0 &&
          type !== 'APPROVAL'
            ? null
            : true
        }
        text={staff.name}
        handleOpenMenu={handleOpenStaffMenu}
        menu={staffMenu()}
      />
      {openDialog === 'EDIT' && (
        <StaffDialog
          title='Edit'
          staff={staff}
          entity={entity}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'QR_DIALOG' && (
        <QRDialog
          qrData={
            staff.userTypeId === 6
              ? `${import.meta.env.VITE_APP_URL}is/${staff.code}`
              : `${import.meta.env.VITE_APP_MANAGE_URL}im/${staff.code}`
          }
          itemName={`${entity.entityName} Invite`}
          handleClose={() => {
            setOpenDialog(null);
            queryClient.invalidateQueries(['users', entity.id]);
          }}
          display={[...staff.code].join('-')}
        />
      )}
      {openDialog === 'LOG_DIALOG' && (
        <LogDialog
          userName={staff.name}
          userId={staff.id}
          entityId={entity.id}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'PERMISSIONS' && (
        <GroupPermissionsDialog
          staff={staff}
          entityId={entity.id}
          handleClose={() => setOpenDialog(null)}
        />
      )}
    </>
  );
}
