import React from 'react';

// LIBRARIES
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { Authenticator, useAuthenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { signIn } from 'aws-amplify/auth';
// import { fetchAuthSession } from 'aws-amplify/auth';

// PAGES
import Dashboard from './pages/Dashboard';
import UserInfo from './pages/UserInfo';
// import ScanQR from "./pages/ScanQR";
// import Login from './pages/Login';
import Account from './pages/Account';
import ProvisionDownload from './pages/ProvisionDownload';
import Invite from './pages/Invite';

// COMPONENTS
import AppToolbar from './components/AppToolbar';
import amplifyExports from './aws-exports';
import { AuthComponents, LoginFormFields } from './components/AuthenticatorComponents';
// import BottomNavigator from './components/BottomNavigator';
import { UserContextProvider } from './contexts/UserContext.js';

// THEME
import amplifyTheme from './styles/amplifyTheme';

// ASSETS
import qrCodeBackground from './assets/qrCode.png';

const RequireAuth = ({ children }) => {

  const services = import.meta.env.VITE_APP_REGION === "local" ? {
    async handleSignIn(input) {
      // custom username and email
      const { username, password } = input;
      return signIn({
        username,
        password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      });
    },
  } : null;

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.1)), url(${qrCodeBackground})`,
        backgroundRepeat: 'repeat',
        backgroundColor: 'transparent',
        height: '100vh',
      }}
    >
      <ThemeProvider theme={amplifyTheme}>
        <Authenticator
          services={services}
          components={AuthComponents(amplifyExports.Auth.Cognito.aws_cognito_social_providers)}
          formFields={LoginFormFields}
          loginMechanisms={['email']}
          signUpAttributes={['phone_number', 'email']}
        >
          {({ signOut, user }) => (
            <UserContextProvider user={user}>
              <AppToolbar />
              <Outlet />
              {/* <BottomNavigator /> */}
            </UserContextProvider>
          )}
        </Authenticator>
      </ThemeProvider>
    </div>
  );
};

const MyRoutes = () => {
  // const location = useLocation();
  // const { route } = useAuthenticator((context) => [context.route]);
  // console.log('ROUTE:', route, location);
  return (
    <Routes>
      {/* <Route path='/login' element={<Login initialState={'signIn'} />} /> */}
      {/* <Route path='/invite/:encodedInvite' element={<Login initialState={'signUp'} />} /> */}
      <Route element={<RequireAuth />}>
        <Route index element={<Dashboard />} />
        <Route path='/invite' element={<Invite />} />
        <Route path='/account' element={<Account />} />
        <Route path='/is/:id' element={<Invite />} />
        <Route path='/im/:id' element={<Invite />} />
        <Route path='/:activeEntityId/:activeTab' element={<Dashboard />} />
        {/* <Route path="/scan-qr" element={<ScanQR />} /> */}
        <Route path='/user-info' element={<UserInfo />} />
        <Route path='/provision-download' element={<ProvisionDownload />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default MyRoutes;
