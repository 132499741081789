import { useState } from 'react';

// MATERIAL
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
// import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';

import { Tooltip } from '@mui/material';
import TopicIcon from '@mui/icons-material/Topic';
import ListIcon from '@mui/icons-material/List';

// ICONS
import FilterList from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import RefreshRounded from '@mui/icons-material/RefreshRounded';

import theme from '../styles/materialTheme';

export default function SearchBar({
  searchText,
  handleText,
  onRefresh,
  handleFilters,
  currentView,
  setCurrentView,
  staffAction,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // TODO FILTER LOGIC
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='space-around' data-tg-tour={'Use the search bar to filter your results'}>
      <Paper
        elevation={3}
        component='form'
        sx={{
          p: '2px 2px',
          display: 'flex',
          alignItems: 'center',
          marginTop: 1,
          marginBottom: 1,
          marginLeft: '5px',
          marginRight: '5px',
          width: '100%',
        }}
      >
        <div>
          {/* <IconButton
                id='basic-button'
                aria-controls={menuOpen ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={menuOpen ? 'true' : undefined}
                onClick={handleMenu}
                sx={{ p: '10px' }}
            >
                <FilterList />
            </IconButton> */}
          <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
            <SearchIcon />
          </IconButton>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <div style={{ padding: 3 }}>
              <FormControl component='fieldset'>
                <FormLabel component='legend' color={'secondary'}>
                  filters
                </FormLabel>
                <FormGroup aria-label='position'>
                  <FormControlLabel
                    value='end'
                    control={<Checkbox color={'secondary'} />}
                    label='Show Out of Range'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='end'
                    control={<Checkbox color={'secondary'} />}
                    label='Show Disabled'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='end'
                    control={<Checkbox color={'secondary'} />}
                    label='Show Offline'
                    labelPlacement='end'
                  />
                </FormGroup>
              </FormControl>
            </div>
          </Menu>
        </div>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder='Search'
          value={searchText}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => handleText(e.target.value)}
        />
        {/* SEARCH ICON Was HERE */}
        <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
        <IconButton onClick={onRefresh} color='primary' sx={{ p: '10px' }}>
          <RefreshRounded />
        </IconButton>
      </Paper>
      {currentView && (
        <>
          {currentView === 'List View' ? (
            <Tooltip title='Group View'>
              <Button
                variant='contained'
                sx={{
                  padding: '12px 12px',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.info.main,
                }}
                onClick={() => setCurrentView('Group View')}
              >
                <TopicIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title='List View'>
              <Button
                variant='contained'
                sx={{
                  padding: '12px 12px',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.info.main,
                }}
                onClick={() => setCurrentView('List View')}
              >
                <ListIcon />
              </Button>
            </Tooltip>
          )}
        </>
      )}
      {/* {staffAction &&             (<Tooltip title='Add'>
              <Button
                variant='contained'
                sx={{
                  padding: '12px 12px',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.info.main,
                }}
                onClick={() => setCurrentView('Group View')}
              >
                <TopicIcon />
              </Button>
            </Tooltip>)} */}
    </Box>
  );
}
