import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Form, FormikProvider } from 'formik'

function FormWrapper(props) {
    const { formik, children, header } = props

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 1,
                marginTop: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.75)'
            }}>
            <Typography variant="h6" gutterBottom>
                { header }
            </Typography>
            <FormikProvider value={formik}>
                <Form autoComplete="off">
                    {children}
                </Form>
            </FormikProvider>
        </Paper>
    );
}

export default FormWrapper;