import { color } from 'chart.js/helpers';

const amplifyTheme = {
  name: 'Sign in Theme',
  tokens: {
    colors: {
      // Colors generated with https://mdigi.tools/color-shades/#ff9903
      brand: {
        primary: {
          10: {
            value: '#ffd89f',
          },
          80: {
            value: '#ff9903', // Used for Primary Buttons
          },
          90: {
            value: '#df8500',
          },
          100: {
            value: '#9f5f00',
          },
        },
      },
    },
    components: {
      button: {
        _hover: {
          borderColor: '#9f5f00',
          backgroundColor: '#ffd89f',
          color: '#9f5f00',
        },

        primary: {
          backgroundColor: '#ff9903',
          _hover: {
            borderColor: '#9f5f00',
            backgroundColor: '#df8500',
          },
          _active: {
            borderColor: '#ff9903',
            backgroundColor: '#fff',
            color: '#ff9903',
          },
        },

        link: {
          color: '#ff9903',
          _hover: {
            borderColor: '#9f5f00',
            backgroundColor: '#ffd89f',
            color: '#9f5f00',
          },
        },
      },
      fieldcontrol: {
        _focus: {
            borderColor: '#ff9903',
            boxShadow: `0 0 0 1px #ff9903`,
        },
      },
      tabs: {
        item: {
          backgroundColor: '#ff9903',
          color: '#fff',
          _active: {
            borderColor: '#ff9903',
            color: '#ff9903',
          },
        },
      },
    },
  },
};

export default amplifyTheme;
