import React, { useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query'; //useQuery
import { useSnackbar } from 'notistack';

// MATERIAL
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// ICONS

// COMPONENTS
import CardLayout from './CardLayout';
import { integrationIcon } from './CardIcons';

// APIS
import {
  deleteIntegration,
} from '../apis/integrations';


export default function CardEntity(props) {
  const { integration, entityId } = props; // onClickFunction
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const deleteAccessKeyMutation = useMutation(deleteIntegration, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['integrations', entityId]);
      enqueueSnackbar('Integration deleted', { variant: 'success' });
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('Failed to delete integration', { variant: 'error' });
    },
  });
  // MENU  *************************************************
  const [anchorEntityMenu, setAnchorEntityMenu] = useState(null);

  const handleOpenEntityMenu = (event) => {
    event.stopPropagation();
    setAnchorEntityMenu(event.currentTarget);
  };

  const menuItems = ['Copy Api Key', 'Delete']; //'Edit',

  const handleCloseEntityMenu = async (e, menuItem) => {
    e.stopPropagation();
    setAnchorEntityMenu(null);
    switch (menuItem) {
      case 'Copy Api Key':
        navigator.clipboard.writeText(integration.apiKey);
        enqueueSnackbar('API Key copied to clipboard', { variant: 'success' });
        console.log(integration.apiKey);
        break;
      case 'Delete':
        const postBody = { id: integration.id };
        console.log('DELETE INVITE: ', postBody);
        deleteAccessKeyMutation.mutate(postBody);
        break;
      default:
        // console.log(menuItem);
        break;
    }
  };

  const integrationMenu = () => {
    return (
      <Menu
        sx={{ mt: '25px' }}
        id='menu-appbar'
        anchorEl={anchorEntityMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEntityMenu)}
        onClose={handleCloseEntityMenu}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem}
            onClick={(e) => handleCloseEntityMenu(e, menuItem)}
          >
            <Typography textAlign='center'>{menuItem}</Typography>
          </MenuItem>
        ))}
      </Menu>
    );
  };
  // ******************************************************

  
  return (
    <>
      <CardLayout
        onClickFunction={null}
        active={true}
        icon={integrationIcon()}
        text={integration.name}
        handleOpenMenu={handleOpenEntityMenu}
        menu={integrationMenu()}
      />
    </>
  );
}
