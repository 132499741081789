import { ApiError, post, get } from 'aws-amplify/api';

export const apiGet = async (path, requestBody) => {

  const apiQuery = { 
    apiName: 'Scanseqr',
    path: path
  }

  if (requestBody) {
    apiQuery.options = {
      queryParams: requestBody
    }
  }

  try {
    const { body } = await get(apiQuery).response;
    const response = await body.json();
    return response;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { 
          statusCode, 
          headers, 
          body 
        } = error.response;
        console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
      // Handle API errors not caused by HTTP response.
      console.error(`Received ${error} error`);
    }
    // Handle other errors.
    console.error(`An error occurred: ${error}`);
  }
};


export const apiPost = async (path, requestBody) => {
  try {
    const restOperation = post({
      apiName: 'Scanseqr',
      path: path,
      options: {
        body: requestBody
      }
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (e) {
    console.log('POST call failed: ', JSON.parse(e.response.body));
  }
}