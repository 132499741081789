import React, { useEffect, useState } from 'react';
// LIBRARIES
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

// MATERIAL
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

// COMPONENTS
import EntityNavigation from '../components/Entities/EntityNavigation';

// FUNCTIONS

// APIS
import { fetchEntitiesQuery } from '../apis/entities';



export default function Dashboard() {
  const { activeEntityId, activeTab } = useParams();
  const navigate = useNavigate();

  // ****************************************************** DASHBOARD ENTITIES STUFF ******************************************************
  // Queries
  const { data: entitiesList, status } = fetchEntitiesQuery();

  const [parentEntityIds, setParentEntityIds] = useState([]);
  const [activeEntity, setActiveEntity] = useState(null);
  const [visibleTab, setVisibleTab] = useState(null);

  useEffect(() => {
    // console.log('ENTITIES: ', entitiesList);
    if (entitiesList?.entities && entitiesList.entities.length > 0) {
      if (!activeEntityId || !activeTab) {
        const rootEntity = entitiesList.entities.find(
          (item) => item.id === entitiesList.rootEntityIds[0]
        );
        if (rootEntity.entityType === 'Site') {
          navigate(`/${rootEntity.id}/devices`);
        } else {
          navigate(`/${rootEntity.id}/entities`);
        }
        return;
      }

      const foundEntity = entitiesList.entities.find(
        (item) => item.id === parseInt(activeEntityId)
      );

      // console.log("ACTIVE: ",foundEntity);
      // Loop through entitiesList and find all the parents of the activeEntity
      const foundParentEntityIds = [];
      let currentEntity = foundEntity;
      while (currentEntity) {
        foundParentEntityIds.unshift(currentEntity.id);
        const currentParentId = currentEntity.parentId;
        const nextEntity = entitiesList.entities.find((e) => e.id === currentParentId);
        currentEntity = nextEntity;
      }

      setParentEntityIds(foundParentEntityIds);
      setVisibleTab(activeTab);
      setActiveEntity(foundEntity);
    }
  }, [entitiesList, activeEntityId, activeTab, navigate]);

  return (
    <div style={{ padding: 7 }}>
      {status === 'loading' && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
      {status === 'error' && <Typography>Have you been invited here?</Typography>}
      {entitiesList?.entities?.length === 0 && status !== 'loading' && (
        <Typography>No Entities found</Typography>
      )}
      {activeEntity && (
        <EntityNavigation
          entities={entitiesList.entities}
          rootEntityIds={[...entitiesList.rootEntityIds]}
          activeEntity={activeEntity}
          parentEntityIds={parentEntityIds}
          visibleTab={visibleTab}
        />
      )}
    </div>
  );
}
