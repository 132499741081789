import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import theme from '../../styles/materialTheme';
import Avatar from '@mui/material/Avatar';

// ICONS
import MuseumIcon from '@mui/icons-material/Museum';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EditIcon from '@mui/icons-material/Edit';

// HOOKS
import useWindowDimensions from '../../hooks/useWindowDimensions';

function NavTitleBlock({ entities, entityHierarchy, editFunction, visibleTab }) {

  // console.log('entities: ', entities)
  // console.log('entityHierarchy: ', entityHierarchy)

  const navigate = useNavigate();
  const { width: displayWidth, divideWidth } = useWindowDimensions();

  const iconStyle = { width: 42, height: 42, color: '#FFFFFF', mr: 1, ml: '5px' };


  const titleIcon = (entity) => {
    switch (entity.entityType) {
      case 'ScanseQR':
        return (
          <Avatar
            data-tg-tour={"ScanceQR - Access EVERYTHING"}
            variant='square'
            sx={{ display: 'flex', mr: 1, ml: '5px' }}
            alt='logo'
            src='/logo192.png'
            onClick={() => navigate(`/${entity.id}/entities`)}
          />
        );
      case 'Partner':
        return (
          <HomeWorkIcon
            data-tg-tour={"Partner - You can add/remove devices/users/modules to any sub entity"}
            sx={iconStyle}
            onClick={() => navigate(`/${entity.id}/entities`)}
            className='icon'
          />
        );
      case 'MultiSite':
        return (
          <MuseumIcon
            data-tg-tour={"MultiSite - You can add/remove admins/users to any sub entity"}
            sx={iconStyle}
            onClick={() => navigate(`/${entity.id}/${visibleTab}`)}
            className='icon'
          />
        );
      default:
        return (
          <AccountBalanceIcon
            sx={iconStyle}
            onClick={() => navigate(`/${entity.id}/${visibleTab}`)}
            className='icon'
          />
        );
    }
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      width={displayWidth < divideWidth ? '100%' : '55%'}
    >
      {titleIcon(entities.find((e) => e.id === entityHierarchy[0]))}
      {entityHierarchy.map(
        (eId, index) =>
          (displayWidth > 1000 ||
            (entityHierarchy.length >= 1 && index >= entityHierarchy.length - 2)) && (
            <React.Fragment key={eId}>
              <Box
                display='flex'
                alignItems='center'
                sx={{
                  padding: '3px',
                  color: theme.palette.primary.main,
                }}
                flexGrow={index === entityHierarchy.length - 1 ? 1 : 0}
              >
                <Button
                  sx={{
                    padding: 1,
                    backgroundColor:
                      index === entityHierarchy.length - 1
                        ? theme.palette.info.main
                        : theme.palette.primary.main,
                    color:
                      index === entityHierarchy.length - 1
                        ? theme.palette.primary.main
                        : theme.palette.info.main,
                  }}
                  onClick={() => navigate(`/${entityHierarchy[index]}/${entities.find((el) => el.id === eId).entityType !== 'Site' && (visibleTab === 'devices' || visibleTab === 'keys') ? 'entities' : visibleTab}`)}
                >
                  {displayWidth > 1000 ||
                    (entityHierarchy.length >= 1 && index >= entityHierarchy.length - 1)
                    ? entities.find((el) => el.id === eId).entityName
                    : '<'}
                </Button>
              </Box>
            </React.Fragment>
          )
      )}
      {editFunction && (
        <Box p={1}>
          <Button
            data-tg-tour={'Click this button to change information relating to your entity.'}
            sx={{
              padding: 1,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.info.main,
            }}
            onClick={() => editFunction('EDIT_ENTITY_DIALOG')}
          >
            <EditIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default NavTitleBlock;
