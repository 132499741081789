import React from 'react';

// LIBRARIES

// MATERIAL
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

// APIS
import {
  useKeyLogsQuery,
} from '../apis/useKeyLogsQuery';

//COMPONENTS
import DialogLayout from '../../../components/DialogLayout';

// FUNCTIONS
import {
  formatDate
} from '../../../functions/formatDate';



const LogDialog = ({ keyItem, handleClose }) => {
  const { data: keyLogs, status } = useKeyLogsQuery(keyItem.id);

  const dialogContent = (
    <>
      {status === 'loading' && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
        {keyLogs && keyLogs.length > 0 ? (
          keyLogs.map((log) => (
            <React.Fragment key={log.collected_time}>
              <Typography variant="caption">
                {formatDate(log.collected_time)} - {log.returned_time ? formatDate(log.returned_time) : "Not returned"}
              </Typography>
              <Typography>
                {log.name ?? "Unallocated"}
              </Typography>
            </React.Fragment>
          ))
        ) : (
          <Typography>No activation logs</Typography>
        )}

    </>
  );

  return (
    <DialogLayout
      dialogTitle={`${keyItem.name} Logs`}
      dialogContent={dialogContent}
      handleDelete={null}
      otherButtons={null}
      handleClose={() => handleClose()}
      handleCreate={null}
    />
  );
};

export default LogDialog;
