/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// import Amplify from 'aws-amplify'; 
// import { AWSIoTProvider } from '@aws-amplify/pubsub';


// MATERIAL
import LinearProgress from '@mui/material/LinearProgress';

// COMPONENTS
import SearchBar from '../../components/SearchBar';
import DevicesTree from './DevicesTree';

// APIS
import { fetchDevices, fetchDevicesMeta } from '../../apis/devices.js'



// Amplify.addPluggable(
//   new AWSIoTProvider({
//     aws_pubsub_region: import.meta.env.VITE_APP_REGION,
//     aws_pubsub_endpoint: import.meta.env.VITE_APP_IOT_ENDPOINT,
//   })
// );


function DeviceTab({ entity }) {
  const [searchItem, setSearchItem] = useState('');
  const [currentView, setCurrentView] = useState('Group View');

  const { data: deviceList, status } = useQuery({
    queryKey: ['devices', entity.id],
    queryFn: () => fetchDevices(entity.id),
    // The query will not execute until follow condition is met
    enabled: entity.entityType === 'Site',
  });

  // Queries
  const { data: deviceMeta } = useQuery({
    queryKey: ['devicesMeta'],
    queryFn: () => fetchDevicesMeta(),
    // The query will not execute until follow condition is met
    enabled: entity.entityType === 'Site',
  });

  return (
    <>
      {status === 'loading' && <LinearProgress color='primary' sx={{ marginBottom: 2 }} />}
      {deviceList && deviceList.groups.length > 0 && deviceMeta && deviceMeta.templates?.length > 0 && (
        <>
          <SearchBar
            searchText={searchItem}
            handleText={(v) => setSearchItem(v)}
            onRefresh={() => setSearchItem('')}
            // handleFilters={handleFilters}
            currentView={currentView}
            setCurrentView={(view) => setCurrentView(view)}
          />
          <DevicesTree
            entity={entity}
            searchItem={searchItem}
            currentView={currentView}
          />
        </>
)}
    </>
  );
}

export default DeviceTab;
