/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Material
import Typography from '@mui/material/Typography';

// COMPONENTS
import CardEntity from '../CardEntity';
import { CardGrid } from '../CardGrid';

// Hooks

// FUNCTIONS

function EntitiesTab({ entitiesList, activeEntity }) {
  const childEntities = entitiesList.filter((item) => item.parentId === activeEntity.id);
  const navigate = useNavigate();

  return !childEntities.length > 0 ? (
    <Typography>No Entities found</Typography>
  ) : (
    CardGrid(
      childEntities.map((entity) => (
        <CardEntity
          key={entity.uuid}
          entity={entity}
          onClickFunction={() => navigate(`/${entity.id}/${entity.entityType === 'Site' ? 'devices' : 'entities'}`)}
        />
      ))
    )
  );
}

export default EntitiesTab;
