import { useEffect } from 'react';
import { pubsub } from '../utils/pubsub';
import mqtt from 'mqtt';

const subscribeToTopic = (topic, callback) => {
    console.log(`MQTT - Subscribing to: ${topic}`, import.meta.env.VITE_APP_REGION);
    if (import.meta.env.VITE_APP_REGION === "local") {
        console.log("Making local connection");
        const client = mqtt.connect(import.meta.env.VITE_APP_IOT_ENDPOINT);

        client.on('connect', () => {
            console.log('Connected to MQTT broker');

            // Subscribe to a topic
            client.subscribe(topic, (err) => {
                if (err) {
                    console.error('Subscription error:', err);
                } else {
                    console.log('Subscribed to topic');
                }
            });
        });

        client.on('message', (topic, message) => {
            console.log(`Received message on topic ${topic}: ${message.toString()}`);
            callback(JSON.parse(message));
        });

        client.on('error', (err) => {
            console.error('MQTT error:', err);
        });

        return client;
    }

    const subscription = pubsub.subscribe({ topics: [topic] }).subscribe({
        next: (data) => callback(data),
        error: (error) => console.error('SUB ERR', error),
        complete: () => console.log('Done'),
    });
    return subscription;
};

const useMQTT = (topic, callback) => {
    useEffect(() => {
        const newSubscription = subscribeToTopic(topic, (data) =>
            callback(data)
        );

        return () => {
            console.log(`MQTT - Unsubscribing from: ${topic}`);
            if (import.meta.env.VITE_APP_REGION === "local") {
                newSubscription.end();
            } else {
                newSubscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useMQTT;
