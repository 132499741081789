import React, { useState } from 'react';
import theme from '../../styles/materialTheme';

// MATERIAL
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

// COMPONENTS
import EntityDialog from '../../components/Dialogs/EntityDialog';
import StaffDialog from '../../features/staff/components/StaffDialog';
import ReservationDialog from '../../features/keysboard/components/ReservationDialog';
import KeysboardDialog from '../../features/keysboard/components/KeysboardDialog';
// import InviteDialog from '../features/staff/components/InviteDialog';
import TabActionBar from './TabActionBar';
import NavTitleBlock from './NavTitleBlock';
import EntitiesTab from './TabEntities';

import StaffTab from '../../features/staff';
import DeviceTab from '../../features/devices';
import KeysboardTab from '../../features/keysboard';


// HOOKS
// import useWindowDimensions from '../../hooks/useWindowDimensions';

// ICONS


// FUNCTIONS

// APIS


function EntityNavigation({entities, rootEntityIds, activeEntity, parentEntityIds, visibleTab }) {
  // console.log("E: ", entities);
  // console.log("rE: ", rootEntityIds);
  // console.log("aE: ", activeEntityId);
  // console.log("pE: ", parentEntityIds);
  // const { width: displayWidth, divideWidth } = useWindowDimensions();


  const [visibleDialog, setVisibleDialog] = useState(null);

  const tabAddAction = (userTypeId) => {
    if (visibleTab === 'users') return () => setVisibleDialog('ADD_STAFF_DIALOG');
    if (visibleTab === 'entities' && userTypeId <= 3)
      return () => setVisibleDialog('ADD_ENTITY_DIALOG');
    // if (visibleTab === 'keys') return () => setVisibleDialog('ADD_RESERVATION_DIALOG');
    if (visibleTab === 'keys' && userTypeId <= 3) return () => setVisibleDialog('ADD_KEYSBOARD_DIALOG');
    return null;
  };

  const getEntityTabs = (entity) => {
    // console.log(entity);
    const commonTabs = [
      { name: 'DEVICES', tab: 'devices', help: 'This is where you find entities like sites, groups and devices' },
      { name: 'USERS', tab: 'users', help: 'This is where you can manage staff access to groups and devices' },
    ];
    if (entity.modules?.length > 0) {
      return [...commonTabs, ...entity.modules];
    }
    return commonTabs;
  };

  const rootEntity = (entityId) => {
      const rE = entities.find((e) => e.id === entityId);
      return (
        <Paper
          variant='outlined'
          sx={{
            padding: '5px',
            marginLeft: '5px',
            marginBottom: '5px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.info.main,
          }}
          key={rE.uuid}
        >
          {/* ROOT ENTITY */}
          <Box display='flex' alignItems='center' flexWrap='wrap' data-testid='root-entity'>
            <NavTitleBlock
              entities={entities}
              entityHierarchy={
                parentEntityIds && parentEntityIds[0] === rE.id ? parentEntityIds : [rE.id]
              }
              editFunction={activeEntity ? setVisibleDialog : null}
              visibleTab={visibleTab}
            />
            {activeEntity && parentEntityIds[0] === rE.id && (
              <TabActionBar
                actions={
                  activeEntity.entityType === 'Site'
                    ? getEntityTabs(activeEntity)
                    : [
                        { name: 'ENTITIES', tab: 'entities', help: 'This is where you find entities like sites, groups and devices' },
                        { name: 'ADMINS', tab: 'users', help: 'This is where you can add and remove staff' },
                      ]
                }
                activeEntityId={activeEntity.id}
                addItem={tabAddAction(rE.userTypeId)}
                visibleTab={visibleTab}
              />
            )}
          </Box>
          {activeEntity && parentEntityIds[0] === rE.id && (
            <Paper
              sx={{
                padding: '5px',
                marginLeft: '5px',
                marginBottom: '5px',
                marginTop: '10px',
              }}
            >
              {visibleTab === 'entities' && (
                <EntitiesTab
                  entitiesList={entities}
                  activeEntity={activeEntity}
                />
              )}
              {visibleTab === 'users' && <StaffTab entity={activeEntity} />}
              {visibleTab === 'devices' && <DeviceTab entity={activeEntity} />}
              {visibleTab === 'keys' && <KeysboardTab entity={activeEntity} />}
            </Paper>
          )}
        </Paper>
      );
  };


  const handleDialogClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setVisibleDialog(null);
  };


  return (
    <>
      {rootEntityIds.map((entityId) => rootEntity(entityId))}
      {visibleDialog === 'ADD_STAFF_DIALOG' && (
        <StaffDialog
          title='Add'
          entity={activeEntity} //nodes
          handleClose={handleDialogClose}
        />
      )}
      {visibleDialog === 'ADD_RESERVATION_DIALOG' && (
        <ReservationDialog keyItem={null} handleClose={handleDialogClose} />
      )}
      {visibleDialog === 'ADD_KEYSBOARD_DIALOG' && (
        <KeysboardDialog title='Add' entity={activeEntity} handleClose={handleDialogClose} />
      )}
      {visibleDialog === 'ADD_ENTITY_DIALOG' && (
        <EntityDialog
          title='Add'
          parent={{
            ...activeEntity,
          }}
          handleClose={handleDialogClose}
        />
      )}
      {visibleDialog === 'EDIT_ENTITY_DIALOG' && (
        <EntityDialog
          title='Edit'
          org={{
            ...activeEntity,
          }}
          handleClose={handleDialogClose}
        />
      )}
    </>
  );
}

export default EntityNavigation;

